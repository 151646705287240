import { useState } from "react";
import { isMobile } from "react-device-detect";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Button,
  HStack,
  Circle,
  Flex,
  Image as Img,
  Text,
} from "@chakra-ui/react";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { DownloadIcon } from "@chakra-ui/icons";
import BeatLoader from "react-spinners/BeatLoader";
import { colorMain } from "../../constants/themes";

const ShareScreenshot = ({
  isOpen,
  onClose,
  image,
  downloadScreenshot,
  downloading,
}) => {
  const shareToWhatsApp = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);
      const imageUrl = canvas.toDataURL("image/png");
      const message = "Check out this receipt:";
      const shareMessage = `${encodeURIComponent(message)} ${encodeURIComponent(
        imageUrl
      )}`;
      shareToWhatsAppMessage(shareMessage);
    };
    img.src = image;
  };

  const shareToWhatsAppMessage = (shareMessage) => {
    let url;
    console.log(url);

    if (isMobile) {
      url = `whatsapp://send?text=${shareMessage}`;
    } else {
      url = `https://web.whatsapp.com/send?text=${shareMessage}`;
    }

    const width = 800;
    const height = 700;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      url,
      "_blank",
      `width=${width}, height=${height}, left=${left}, top=${top}`
    );
  };

  return (
    <Modal size={["xl", "md"]} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share screenshot</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          display="flex"
          flexDir="column"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <FormControl mt={4} mb={6}>
            <Button fontSize={["md", "xl"]}>
              Share receipt as screenshot to whatsapp or download and save{" "}
            </Button>
          </FormControl>
          <Flex
            display="flex"
            flexDir="column"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            pb={4}
          >
            {image ? (
              <Img
                src={image}
                alt="loading..."
                top="0"
                left="0"
                w="80%"
                // h={["100%", "100%"]}
                objectFit="cover"
                objectPosition="top"
              />
            ) : (
              <Text
                fontSize="xl"
                py={5}
                fontWeight="semibold"
                fontStyle="italic"
              >
                Taking screenshot . . .
              </Text>
            )}
          </Flex>
          {console.log(downloading)}
          <HStack gap={3}>
            {!downloading ? (
              <>
                <Button
                  my={2}
                  background="transparent"
                  _hover={{
                    backgroundColor: "transparent",
                  }}
                  onClick={() => shareToWhatsApp()}
                  isDisabled={downloading || !image}
                  isLoading={downloading || !image}
                >
                  <Circle bg="gray.100" size="42px">
                    <Circle bg="gray.100" size="42px">
                      <WhatsappIcon size={30} round={true} />
                    </Circle>
                  </Circle>
                </Button>

                <Button
                  background="transparent"
                  _hover={{
                    backgroundColor: "transparent",
                  }}
                  onClick={downloadScreenshot}
                  isDisabled={downloading || !image}
                  isLoading={downloading || !image}
                >
                  <Circle bg="gray.100" size="42px">
                    <DownloadIcon fontSize="3xl" />
                  </Circle>
                </Button>
              </>
            ) : (
              <BeatLoader size={8} color={colorMain} />
            )}
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareScreenshot;
