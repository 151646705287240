import React from "react";
import { Select } from "@chakra-ui/react";

const DynamicSelectInput = ({
  options = [],
  value,
  onChange,
  placeholder = "Select an option",
  condition,
}) => {
  return (
    <Select
      backgroundColor="white"
      border="1px solid gray"
      borderColor="black"
      borderRadius={4}
      mb="12px"
      fontSize="2xl"
      size="lg"
      autoComplete="off"
      onChange={onChange}
      value={value}
      required
      userSelect="none"
      placeholder={!condition && placeholder}
      _hover={{
        outline: "none",
      }}
      _active={{
        bg: "white",
        color: "#2fb5b0",
        outline: "none",
      }}
    >
      {condition ? (
        <option defaultValue={value?.trim}>{value}</option>
      ) : (
        options?.map((opt, index) => (
          <option
            key={index}
            value={opt?.value}
            style={{
              border: "1px solid gray",
            }}
          >
            {opt?.value}
          </option>
        ))
      )}
    </Select>
  );
};

export default DynamicSelectInput;

export const DynamicSelectInputEvents = ({
  options = [],
  value,
  onChange,
  placeholder = "Select an option",
  condition,
}) => {
  return (
    <Select
      backgroundColor="white"
      border="1px solid gray"
      borderRadius={4}
      py={3}
      mb="0px"
      fontSize="14px"
      size="md"
      w="fit-content"
      autoComplete="off"
      onChange={onChange}
      value={value}
      required
      userSelect="none"
      placeholder={!condition && placeholder}
      _hover={{
        outline: "none",
      }}
      _active={{
        bg: "white",
        color: "#2fb5b0",
        outline: "none",
      }}
    >
      {condition ? (
        <option defaultValue={value?.trim}>{value}</option>
      ) : (
        options?.map((opt, index) => (
          <option
            key={index}
            value={opt?.value?.trim()}
            style={{
              border: "1px solid gray",
            }}
          >
            {opt?.value}
          </option>
        ))
      )}
    </Select>
  );
};
