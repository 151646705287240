import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  Flex,
  FormControl,
  Text,
  Input,
} from "@chakra-ui/react";
import { useRef } from "react";
import { colorMain } from "../../constants/themes";

const PledgePopUp = ({
  pledge,
  isPledgeOpen,
  setIsOpenPledge,
  setAmount,
  pledgeWord,
}) => {
  const cancelRef = useRef();
  const handleClick = () => {
    setAmount(pledge);
    setIsOpenPledge(false);
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={() => setIsOpenPledge(false)}
      // isOpen={true}
      isOpen={isPledgeOpen}
      isCentered
    >
      <AlertDialogOverlay />
      {/* {console.log(isPledgeOpen)} */}
      <AlertDialogContent borderRadius={12}>
        <AlertDialogHeader>{pledgeWord}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <FormControl>
            <Flex
              shadow="base"
              borderRadius="10px"
              flexDir="column"
              px={3}
              pt={4}
              height="fit-content"
            >
              <Text
                fontWeight="semibold"
                fontSize="1.4rem"
                pb={0}
                mb={0}
                px={3}
              >
                You have a {pledgeWord?.toLowerCase()} of Amount
              </Text>
              <Flex
                pb={2}
                alignItems="baseline"
                justifyContent="center"
                fontWeight="semibold"
                ml={
                  !pledge || pledge?.length < 1
                    ? "12"
                    : pledge?.length > 4
                    ? pledge?.length - 2
                    : "16"
                }
              >
                <span
                  style={{
                    color: "orange",
                    fontWeight: "semibold",
                    fontSize: "18px",
                    marginRight: "3px",
                  }}
                >
                  KES
                </span>
                <Input
                  variant="unstyled"
                  fontSize={["7xl", "7xl"]}
                  value={pledge}
                  isReadOnly
                  required
                  ml={!pledge || pledge?.length < 1 ? "12" : "4"}
                  w="fit-content"
                  // color={colorMain}
                  fontWeight="semibold"
                  // borderBottom="1px solid black"
                />
              </Flex>
            </Flex>
          </FormControl>
        </AlertDialogBody>
        {/* <AlertDialogFooter width='100%'> */}
        <Flex alignItemss="center" py={4} px={3} fontSize="xl">
          <Flex bg={colorMain} w="100%" borderRadius={8} p={2}>
            <Button
              ref={cancelRef}
              size="lg"
              backgroundColor="#062a30"
              color="white"
              width="100%"
              onClick={() => setIsOpenPledge(false)}
            >
              Pay Later
            </Button>
            <Button
              width="100%"
              size="lg"
              onClick={() => handleClick()}
            >
              Pay Now
            </Button>
          </Flex>
        </Flex>
        {/* </AlertDialogFooter> */}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default PledgePopUp;
