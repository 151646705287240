import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  Flex,
  FormControl,
  Text,
  Input,
  FormHelperText,
  FormLabel,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import { colorMain } from "../../constants/themes";
import FadeIn from "react-fade-in/lib/FadeIn";
import { handlePaySub } from "../../utils/api";
import BeatLoader from "react-spinners/BeatLoader";
import { CheckCircleIcon } from "@chakra-ui/icons";

const SubPopUp = ({
  subAmount,
  isSubOpen,
  number,
  subscribeData,
  sales_code,
  toast,
  org_id,
  setIsSubLoading,
  setMemberSub,
  setIsOpenSub,
  isSubLoading,
  memberSub,
}) => {
  const [payNow, setPayNow] = useState(false);
  const [triggerSub, setTriggerSub] = useState(false);
  const [checkSubscription, setCheckSubscription] = useState(false);

  const handleClick = () => {
    setPayNow(true);
    // setIsOpenSub(false);
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        window.location.reload();
      }}
      // isOpen={true}
      isOpen={isSubOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius={12}>
        <AlertDialogHeader fontWeight="bold" fontSize="2xl" color="gray.700">
          {checkSubscription ? "checking subscription" : "Pay subscription"}
        </AlertDialogHeader>
        {!triggerSub && !isSubLoading && !checkSubscription && <AlertDialogCloseButton />}
        <AlertDialogBody>
          <FormControl>
            <Flex
              borderRadius="10px"
              flexDir="column"
              px={3}
              pt={4}
              height="fit-content"
            >
              {checkSubscription ? (
                <Flex alignItems="center" justifyContent="center" py={5}>
                  {isSubLoading ? (
                    <BeatLoader size={16} color={colorMain} />
                  ) : (
                    <Text
                      alignItems="center"
                      textAlign="center"
                      display="flex"
                      gap={4}
                      color="teal"
                      fontWeight="semibold"
                      justifyContent="center"
                    >
                      You have successfully subscribed{" "}
                      <Icon as={CheckCircleIcon} color="teal" />
                    </Text>
                  )}
                </Flex>
              ) : (
                <>
                  <Text
                    fontWeight="semibold"
                    fontSize="1.4rem"
                    pb={0}
                    mb={0}
                    px={1}
                  >
                    The organizers of this collection require that members pay
                    for the subscription fee of :
                  </Text>
                  <Flex
                    pb={2}
                    alignItems="baseline"
                    justifyContent="center"
                    fontWeight="semibold"
                    ml={
                      !subAmount || subAmount?.length < 1
                        ? "12"
                        : subAmount?.length > 4
                        ? subAmount?.length - 2
                        : "16"
                    }
                  >
                    <span
                      style={{
                        color: "orange",
                        fontWeight: "semibold",
                        fontSize: "18px",
                        marginRight: "1px",
                      }}
                    >
                      KES
                    </span>
                    <Input
                      variant="unstyled"
                      fontSize={["7xl", "7xl"]}
                      value={subAmount}
                      isReadOnly
                      required
                      ml={14}
                      w="fit-content"
                      color={colorMain}
                      fontWeight="bold"
                      // borderBottom="1px solid black"
                    />
                  </Flex>
                </>
              )}
              {!checkSubscription && !memberSub && payNow && (
                <FadeIn>
                  <FormControl fontSize="2xl" mt={2}>
                    <FormLabel fontSize="xl" fontWeight="bold">
                      Phone number
                    </FormLabel>
                    <Flex flexDir="column" alignItems="center">
                      <Input
                        mb={1}
                        ml={3}
                        mr={3}
                        type="tel"
                        // width="fit-content"
                        fontSize="2xl"
                        textAlign="center"
                        isReadOnly
                        value={number}
                      />
                    </Flex>
                    <FormHelperText
                      textAlign="center"
                      color="orange"
                      fontSize="md"
                      m={0}
                      py={0}
                      mb={8}
                    >
                      "A payment request shall be sent to this number"
                    </FormHelperText>
                  </FormControl>
                </FadeIn>
              )}
            </Flex>
          </FormControl>
        </AlertDialogBody>
        {/* <AlertDialogFooter width='100%'> */}
        <Flex
          alignItems="center"
          justifyContent="center"
          py={4}
          px={3}
          fontSize="xl"
        >
          {!checkSubscription && !memberSub ? (
            payNow ? (
              <Button
                // width="100%"
                py={5}
                px={10}
                borderRadius={6}
                color="white"
                fontWeight="bold"
                fontSize="xl"
                size="lg"
                bg={colorMain}
                isLoading={triggerSub}
                onClick={() =>
                  handlePaySub(
                    sales_code,
                    subAmount,
                    number,
                    setTriggerSub,
                    subscribeData,
                    toast,
                    setMemberSub,
                    org_id,
                    setIsSubLoading,
                    setCheckSubscription
                  )
                }
              >
                Pay
              </Button>
            ) : (
              <Button
                // width="100%"
                py={5}
                px={10}
                borderRadius={6}
                color="white"
                fontWeight="bold"
                fontSize="xl"
                size="lg"
                bg={colorMain}
                onClick={() => handleClick()}
              >
                Click to Pay Subscription
              </Button>
            )
          ) : (
            <Button
              // width="100%"
              py={5}
              px={10}
              borderRadius={6}
              color="white"
              fontWeight="bold"
              fontSize="xl"
              size="lg"
              bg={colorMain}
              onClick={() => setIsOpenSub(false)}
            >
              Proceed to Pay
            </Button>
          )}
        </Flex>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SubPopUp;
