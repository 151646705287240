import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  Button,
  HStack,
  Circle,
} from "@chakra-ui/react";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { FaRegClipboard, FaRegCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ShareModal = ({ isOpen, onClose, data }) => {
  const [copied, setCopied] = useState(false);

  const truncateAndEllipsize = (str, maxLength) =>
    str && str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;

  const templateType =
    data?.data.saledata?.sale_settings?.template_type || "default";

  const getActionWord = (templateType) =>
    templateType === "realestate"
      ? "Pay"
      : templateType === "events"
      ? "Buy Ticket"
      : templateType === "fundraiser"
      ? "Contribute"
      : templateType === "business"
      ? "Buy"
      : templateType === "donations"
      ? "Donate"
      : templateType === "chamas"
      ? "Send Contribution"
      : "Contribute";

  return (
    <Modal size={["xl", "md"]} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share Payment Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pb={6}
          display="flex"
          flexDir="column"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <FormControl mt={4} mb={6}>
            <Button fontSize={["md", "xl"]}>{`${truncateAndEllipsize(
              window.location.href?.replace("https://", ""),
              36
            )}`}</Button>
          </FormControl>
          <HStack gap={3}>
            <Circle bg="gray.100" size="40px">
              <WhatsappShareButton
                title={`*${
                  data?.data.saledata.contact_name || "Your Contact Name"
                }*\n`}
                url={`Click on this link to ${getActionWord(templateType)}: ${
                  window.location.href
                }`}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </Circle>
            <Circle bg="gray.100" size="40px">
              <CopyToClipboard
                onCopy={() => setCopied(true)}
                text={window.location.href}
              >
                <button type="button" aria-label="Copy to Clipboard Button">
                  {copied ? <FaRegClipboard /> : <FaRegCopy />}
                </button>
              </CopyToClipboard>
            </Circle>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
