import React, { useEffect, useRef, useState } from "react";
import { generateStatement } from "./Content/pdfMaker";
import { Button, Flex, Input } from "@chakra-ui/react";
import { colorMain } from "../constants/themes";
import {
  handleGenerateStatements,
  handleGetStatementDetails,
} from "../utils/api";
import { createFileName, useScreenshot } from "use-react-screenshot";
import { useNavigate, useParams } from "react-router";
import LoadingPage from "./LoadingPage";
import FourOFour from "./404";
import { ToastContainer, toast } from "react-toastify";

import { Text, Circle, Spinner, Card, Icon } from "@chakra-ui/react";
import { WarningIcon, SearchIcon } from "@chakra-ui/icons";
import PaymentConfirmation from "./Features/PaymentConfirmation";
import {
  formatterPaidBy,
  formatterTimePaid,
  titleCase,
  downloadPdf,
} from "../utils/functions";
import { AiFillCheckCircle } from "react-icons/ai";

const StatementDownload = () => {
  const navigate = useNavigate();
  let { mpesaCode } = useParams();
  // console.log(mpesaCode);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState(false);
  const [load, setLoad] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [time, setTime] = useState(100);
  const [searchMpesaCode, setSearchMpesaCode] = useState("");
  setTimeout(() => {
    setTime(0);
  }, 60000);
  const ref = useRef(null);
  const refs = useRef(null);
  const initialRef = useRef();

  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const [downloading, setIsDownloading] = useState(false);
  const [statement, setStatement] = useState(true);

  useEffect(() => {
    mpesaCode &&
      handleGetStatementDetails(mpesaCode, setData, toast, setClicked, setLoad);
  }, [mpesaCode]);

  useEffect(() => {
    if (!mpesaCode) {
      initialRef?.current?.focus();
      toast.warning(`Please enter an MPESA code to search!`);
    }
  }, []);

  useEffect(() => {
    if (!load && !data && time !== 0 && !searchMpesaCode ) {
      initialRef?.current?.focus();
      toast.warning(`Please enter an MPESA code to search!`);
    }
  }, [load]);

  const getImage = async () => {
    if (statement) {
      setStatement(false);
      return;
    }

    setIsDownloading(true);

    try {
      const dataUri = await takeScreenshot(refs.current);
      downloadImage(dataUri);
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }

    setIsDownloading(false);
    setStatement(true);
  };

  useEffect(() => {
    if (!statement) {
      getImage();
    }
  }, [statement]);

  const handleSearch = () => {
    if (searchMpesaCode.trim()) {
      navigate(`/statement/${searchMpesaCode.trim()}`);
    } else {
      toast.error("Please enter a valid Mpesa code.");
    }
  };

  const downloadImage = (dataUri) => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = dataUri;
    link.download = "statement.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  let search = (
    <>
      {" "}
      <ToastContainer />
      <Card bg="white" shadow="4xl" p={4}>
        <Flex alignItems="center" justifyContent="center">
          <Circle
            bg="white"
            size="20"
            // top="-35px"
            left="-40px"
            position="absolute"
          >
            <SearchIcon fontSize="30px" color={colorMain} />
          </Circle>
          <Text fontWeight="bold" fontSize="3xl">
            Get statements by Mpesa transaction code!
          </Text>
        </Flex>
        <Text fontSize="3xl" color="gray">
          Please enter a valid Mpesa code to search
        </Text>
        <Flex
          mt={4}
          flexDirection="column"
          alignItems="center"
          gap={3}
          fontSize="3xl"
        >
          <Input
            placeholder="Enter Mpesa Code"
            value={searchMpesaCode}
            onChange={(e) => setSearchMpesaCode(e.target.value)}
            maxWidth="500px"
            padding={4}
            size="5xl"
            borderColor={colorMain}
            borderRadius="5px"
            ref={initialRef}
          />
          <Button
            bgColor={colorMain}
            shadow="sm"
            px={12}
            py={7}
            color={statement ? "white" : `${colorMain}`}
            borderRadius="md"
            fontSize={["xl", "2xl"]}
            fontWeight="bold"
            onClick={handleSearch}
            isDisabled={!searchMpesaCode || (searchMpesaCode && searchMpesaCode?.length !== 10)}
          >
            Search
          </Button>
        </Flex>
      </Card>
    </>
  );

  return (
    <>
      {!mpesaCode ? (
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          minWidth="370px"
          p={4}
        >
          {search}
        </Flex>
      ) : (
        data && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mb={2}
            mt={2}
            width={["100vw", "", ""]}
            height={["100vh"]}
          >
            <ToastContainer />
            {clicked ? (
              <Flex
                h="300px"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="white"
                  size="xl"
                />
                <Text fontSize="md">Checking records...</Text>
              </Flex>
            ) : (
              <Flex
                flexDir="column"
                p={3}
                ref={refs}
                minWidth="370px"
                maxW="100vw !important"
              >
                <Card
                  bg="white"
                  shadow="2xl"
                  color={colorMain}
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                  justifyContent="center"
                  borderTopRadius="2xl"
                  borderBottomRadius="none"
                  pt={3}
                  mt={12}
                  position="relative"
                  borderWidth=".001rem"
                  borderBottom="none"
                  borderColor="#cccccc"
                >
                  <Circle bg="white" size="24" top="-35px" position="absolute">
                    <AiFillCheckCircle fontSize="50px" color="#40D195" />
                  </Circle>
                  <Text
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3xl"
                    pt={1}
                    mt="20px"
                    pb={0}
                    mb={0}
                  >
                    {"Payment record Found!"}
                  </Text>
                  <Text
                    fontWeight="medium"
                    p={1}
                    color="gray"
                    fontSize="1.2rem"
                    pb={4}
                    textAlign="center"
                  >
                    Thank you, your payment records are available.
                  </Text>
                  <hr
                    style={{
                      padding: "0%",
                      margin: "0%",
                      width: "90%",
                      height: ".083rem",
                      color: "#062a30af",
                      opacity: "0.3",
                    }}
                  />
                </Card>

                <Card
                  bg="white"
                  color={colorMain}
                  pb={6}
                  borderTopRadius="none"
                  borderBottomRadius="2xl"
                  mb={8}
                  // outline={`.2rem solid ${colorMain}`}
                  borderWidth=".001rem"
                  borderTop="none"
                  borderColor="#cccccc"
                >
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    p={6}
                    py={0}
                    ref={ref}
                    background="transparent"
                  >
                    <Text p={0} color="gray" pt={3} pb={0}>
                      Total Amount
                    </Text>
                    <Flex
                      p={0}
                      display="flex"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Text fontWeight="bold" color="gray">
                        KES
                      </Text>
                      <Text fontWeight="bold" ml={2} fontSize="6xl">
                        {data?.transactions?.length > 0
                          ? data?.transactions
                              .reduce(
                                (acc, payment) => acc + payment.payment_amount,
                                0
                              )
                              ?.toLocaleString()
                          : "1.00"}
                      </Text>
                    </Flex>
                    <hr
                      style={{
                        padding: "0%",
                        margin: "0%",
                        width: "90%",
                        height: ".043rem",
                        color: "#062a30af",
                        opacity: "0.025",
                      }}
                    />

                    {data?.transactions?.length && (
                      <PaymentConfirmation
                        title="Number of payments:"
                        content={data?.transactions?.length}
                      />
                    )}

                    <PaymentConfirmation
                      title="Statements for:"
                      content={titleCase(
                        formatterPaidBy(data?.name) || "Anonymous"
                      )}
                    />

                    {(data.phone ||
                      data?.transactions?.[0]?.customer_phone) && (
                      <PaymentConfirmation
                        title="Phone number"
                        content={
                          data.phone ||
                          data?.transactions?.[0]?.customer_phone ||
                          "2547********"
                        }
                      />
                    )}

                    {data?.collection && (
                      <PaymentConfirmation
                        title="For"
                        content={data?.collection}
                      />
                    )}

                    <Flex
                      w="100%"
                      alignItems="center"
                      mb={6}
                      justifyContent="center"
                    >
                      <hr
                        style={{
                          padding: "0%",
                          margin: "0%",
                          width: "95%",
                          height: ".083rem",
                          color: "#062a30af",
                          opacity: "0.31",
                        }}
                      />
                    </Flex>
                  </Flex>

                  <Flex alignItems="center" justifyContent="center">
                    <Button
                      bgColor={colorMain}
                      shadow="sm"
                      px={12}
                      py={7}
                      color={statement ? "white" : `${colorMain}`}
                      borderRadius="md"
                      fontSize={["xl", "2xl"]}
                      fontWeight="bold"
                      isDisabled={processing || downloading}
                      isLoading={processing || downloading}
                      loadingText="processing.."
                      onClick={() => {
                        if (data && data.transactions) {
                          handleGenerateStatements({
                            details: data,
                            number:
                              data.phone ||
                              data?.transactions?.[0]?.customer_phone ||
                              "2547********",
                            paid_by: data.name || "Anonymous",
                            toast,
                            downloadPdf,
                            collection_type: data.template,
                            setProcessing,
                            from: true,
                          });
                        } else {
                          toast.error("Please refresh and try again!");
                        }
                      }}
                    >
                      <Flex alignItems="center" justifyContent="center" gap={2}>
                        Download Statement
                      </Flex>
                    </Button>
                  </Flex>
                </Card>
              </Flex>
            )}
          </Flex>
        )
      )}

      {(load || time === 60000) && <LoadingPage />}

      {!load && !data && time === 0 && <FourOFour />}
      {!load && !data && time !== 0 && (
        <Flex
          flexDir="column"
          p={3}
          ref={refs}
          alignItems="center"
          justifyContent="center"
          minWidth="370px"
          height="100vh"
          maxW="100vw !important"
        >
          <ToastContainer />
          <Card
            bg="white"
            minWidth="370px"
            boxShadow="none"
            shadow="2xl"
            color={colorMain}
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            borderTopRadius="2xl"
            borderBottomRadius="none"
            pt={3}
            mt={12}
            position="relative"
            borderWidth=".001rem"
            borderColor="red"
          >
            <Circle bg="white" size="24" top="-35px" position="absolute">
              <WarningIcon fontSize="50px" color="red" />
            </Circle>
            <Text
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="3xl"
              pt={1}
              mt="20px"
              pb={0}
              mb={0}
            >
              {"Payment Record Not Found!"}
            </Text>
            <Text
              fontWeight="medium"
              p={1}
              color="gray"
              fontSize="1.4rem"
              pb={4}
              textAlign="center"
            >
              Sorry! Payment record not found.
            </Text>
            <hr
              style={{
                padding: "0%",
                margin: "0%",
                width: "90%",
                height: ".083rem",
                color: "#062a30af",
                opacity: "0.3",
              }}
            />
          </Card>
          <Flex>{search}</Flex>
        </Flex>
      )}
    </>
  );
};
export default StatementDownload;
