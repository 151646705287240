import { Text, Flex, Image, VStack, HStack } from "@chakra-ui/react";
import React from "react";
import { ToastContainer } from "react-toastify";

const Standard = React.memo((props) => {
  const {
    type,
    dataSale,
    payPart,
    original_date,
    docTermsService,
    docImageUrl,
    paid,
    bg,
    logo,
    docHexCode,
    paymentData,
    handlePayment,
    handleGeneratePdfDocument,
  } = props;
  const colorCode = docHexCode || "black";
  const calcTotal =
    dataSale?.sale_amount -
    dataSale?.sale_balance +
    Math.trunc(
      dataSale?.sale_tax >= 1
        ? (dataSale?.sale_tax / 100) * dataSale?.sale_amount
        : dataSale?.sale_tax * dataSale?.sale_amount
    ) +
    Math.trunc(
      dataSale?.sale_discount >= 1
        ? (dataSale?.sale_discount / 100) * dataSale?.sale_amount
        : dataSale?.sale_discount * dataSale?.sale_amount
    );

  return (
    <Flex
      width="100%"
      minH="100vh"
      boxSizing="border-box"
      flexDir="column"
      alignItems="center"
      justifyContent={["normal", "center"]}
      bgImage={bg}
    >
      <Flex
        padding={["16px", "20px"]}
        paddingTop={["2rem", ""]}
        flexDir="column"
        minH={["90vh", "100vh"]}
        w="100%"
        bg="white"
        fontSize={["1.44rem", "2xl"]}
        shadow="base"
        maxW={["100%", "588px"]}
        justifyContent="space-between"
        alignItems="center"
      >
        <ToastContainer zIndex="1001" />
        <Flex alignItems="center" justifyContent="space-between">
          <HStack alignItems="center">
            {docImageUrl ? (
              <Image
                src={docImageUrl}
                width={["20%", "15%"]}
                padding={0}
                alt=""
              />
            ) : (
              <Text>&nbsp;</Text>
            )}
            <Text fontWeight="bold" textAlign="left" padding={0}>
              {dataSale.org_data.name?.toUpperCase()}
            </Text>
          </HStack>
          {dataSale.sale_amount - calcTotal === 0 && (
            <Image src={paid} width={["30%", "15%"]} alt="" />
          )}
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          paddingTop="1rem"
          w="100%"
        >
          <Flex flexDir="column">
            <Text fontWeight="bold" color={colorCode}>
              Client Name:
            </Text>
            <Text> {dataSale.contact_name}</Text>
          </Flex>
          <Flex flexDirection="column" textAlign="right">
            <Text fontWeight="bold" color={colorCode}>
              {type
                ? type === "Sale"
                  ? "BILL"
                  : type === "Quote"
                  ? "QUOTE"
                  : type === "Invoice"
                  ? "INVOICE"
                  : "RECEIPT"
                : "RECEIPT"}
            </Text>
            <Text>#{dataSale.sale_trx_code}</Text>
          </Flex>
        </Flex>
        <Flex flexDir="column" textAlign="left" width="100%">
          <Text fontWeight="bold" color={colorCode}>
            Date:
          </Text>
          <Text>{original_date}</Text>
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          paddingTop={["1rem", "revert"]}
        >
          <table className="table">
            <thead>
              <tr
                style={{
                  color: `${colorCode}`,
                  borderBottom: `3px solid ${colorCode}`,
                }}
              >
                <th className="align">Description</th>
                <th style={{ textAlign: "center" }}>Amount</th>
                <th style={{ textAlign: "center" }}>Quantity</th>
                <th style={{ textAlign: "right" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {dataSale.items_data.map((item) => {
                return (
                  <tr key={item.uniqueId} style={{ fontWeight: "normal" }}>
                    <td className="align" style={{ fontWeight: "normal" }}>
                      {item.item_name}
                    </td>
                    <td style={{ textAlign: "center", fontWeight: "normal" }}>
                      {item.item_amount}
                    </td>
                    <td style={{ textAlign: "center", fontWeight: "normal" }}>
                      {item.item_quantity}
                    </td>
                    <td className="text-right" style={{ fontWeight: "normal" }}>
                      {item.item_quantity * item.item_amount}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Flex justifyContent="right" pr={["4"]}>
            <VStack fontWeight="bold">
              {dataSale?.sale_amount === dataSale?.sale_balance - calcTotal ? (
                <HStack
                  width="100%"
                  alignItems="center"
                  fontSize={["1.7rem", "1.6rem"]}
                  justifyContent="space-between"
                  color={colorCode}
                  borderTop={[
                    `1px solid ${colorCode}`,
                    `2px solid ${colorCode}`,
                  ]}
                  py={2}
                  borderBottom={[
                    `1px solid ${colorCode}`,
                    `2px solid ${colorCode}`,
                  ]}
                >
                  <Text>Total:</Text>
                  <Text>
                    <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                    {dataSale?.sale_amount}
                  </Text>
                </HStack>
              ) : (
                <>
                  <HStack
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    color={colorCode}
                  >
                    <Text>Total:</Text>
                    <Text>
                      <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                      {dataSale?.sale_amount}
                    </Text>
                  </HStack>
                  {dataSale?.sale_amount !== dataSale?.sale_balance && (
                    <HStack
                      width="100%"
                      alignItems="center"
                      mt={1}
                      justifyContent="space-between"
                      color={colorCode}
                    >
                      <Text>Paid:</Text>
                      <Text>
                        <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                        {dataSale?.sale_amount - dataSale?.sale_balance}
                      </Text>
                    </HStack>
                  )}
                  {dataSale?.sale_discount > 0 && (
                    <HStack
                      width="100%"
                      alignItems="center"
                      mt={1}
                      justifyContent="space-between"
                      color={colorCode}
                    >
                      <Text>Discount:</Text>
                      <Text>
                        <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;{" "}
                        {dataSale?.sale_discount >= 1
                          ? Math.trunc(
                              (dataSale?.sale_discount / 100) *
                                dataSale?.sale_amount
                            )
                          : Math.trunc(
                              dataSale?.sale_discount * dataSale?.sale_amount
                            )}
                      </Text>
                    </HStack>
                  )}
                  {dataSale?.sale_tax > 0 && (
                    <HStack
                      width="100%"
                      alignItems="center"
                      mt={1}
                      justifyContent="space-between"
                      color={colorCode}
                    >
                      <Text>Tax:</Text>
                      <Text>
                        <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                        {dataSale?.sale_tax >= 1
                          ? Math.trunc(
                              (dataSale?.sale_tax / 100) * dataSale?.sale_amount
                            )
                          : Math.trunc(
                              dataSale?.sale_tax * dataSale?.sale_amount
                            )}
                      </Text>
                    </HStack>
                  )}
                  <HStack
                    width="100%"
                    alignItems="center"
                    mt={1}
                    justifyContent="space-between"
                    py={2}
                    borderTop="1px solid black"
                    borderBottom="1px solid black"
                  >
                    <Text>Sub total</Text>
                    <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                    <Text>
                      <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                      {dataSale.sale_amount - calcTotal}
                    </Text>
                  </HStack>
                </>
              )}
            </VStack>
          </Flex>
        </Flex>
        <Flex
          width="100%"
          justifyContent="space-between"
          mt={["2rem", "revert"]}
          pr={["4"]}
        >
          {/* <Flex flexDir='column'>
                        {
                            paymentData &&
                            <>
                                <Text fontWeight='bold' color={colorCode}>Payment method</Text>
                                <Flex>
                                    <UnorderedList>
                                        <ListItem fontWeight='bold'>{paymentData.pay_type_main}</ListItem>
                                        {
                                            paymentData.pay_detail_2 ?
                                                <>
                                                    <ListItem listStyleType='none'>Paybill number: {paymentData.pay_detail_1}</ListItem>
                                                    <ListItem listStyleType='none'>Account number: {paymentData.pay_detail_2}</ListItem>
                                                </>
                                                :
                                                <ListItem listStyleType='none'>Till number: {paymentData.pay_detail_1}</ListItem>
                                        }
                                    </UnorderedList>
                                </Flex>
                            </>
                        }
                    </Flex>
                     <Flex flexDirection='column'>
                        <Text fontWeight='bold'>DUE DATE</Text>
                        <Text>March 27, 2023</Text>
                    </Flex> */}
        </Flex>
        <Flex
          w="100%"
          alignItems="center"
          fontSize={["xl", "normal"]}
          justifyContent="space-between"
          fontWeight="bold"
          color={colorCode}
          mt={["2rem", "1rem"]}
          pr={["4"]}
        >
          <Text>
            Phone:{" "}
            <span style={{ fontWeight: "normal" }}>
              {dataSale.org_data.main_phone_number}
            </span>
          </Text>
          <Text>
            Location:{" "}
            <span style={{ fontWeight: "normal" }}>
              {" "}
              {dataSale.org_data.location}
            </span>
          </Text>
          {/* <Text>Email: <span style={{ fontWeight: 'normal' }}>lensworld@gmail.com</span></Text> */}
        </Flex>
        <Flex paddingTop="1rem">
          {payPart &&
            React.cloneElement(payPart, {
              handlePayment,
              handleGeneratePdfDocument,
              calcTotal,
            })}
        </Flex>
        <Flex
          borderTop="2px solid black"
          pt={2}
          borderColor={colorCode}
          w="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize={["1.2rem", "revert"]} w="60%">
            {docTermsService}
          </Text>
          <Flex w="30%" flexDir="column" alignItems="end">
            <Text fontSize=".9rem" p={0} pr={7}>
              Powered by
            </Text>
            <Image src={logo} p={0} width={["60%", "35%"]} alt="Nobuk" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});

export default Standard;
