import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef } from "react";
import { FaDownload } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { colorAlt } from "../../constants/themes";
import mpesa from "../../assets/img/mpesaversion.webp";
import { handleInput } from "../../utils/functions";

export function PayPart(props) {
  const inputRef = useRef();

  const {
    setPhoneLength,
    setNumber,
    subActive,
    setAmount,
    calcTotal,
    amount,
    clicked,
    data,
    handleGeneratePdfDocument,
    setName,
    handlePayment,
    isOpen,
    number,
    onClose,
    onOpen,
    trigger,
    type,
    dataSale,
    name,
    payLink,
    phoneLength,
  } = props;

  useEffect(() => {
    if (
      !inputRef.current ||
      !inputRef.current.contains(document.activeElement)
    ) {
      type !== "Collection" &&
        type !== "Wallet" &&
        amount !== dataSale?.sale_amount - calcTotal &&
        setAmount(dataSale?.sale_amount - calcTotal);
    }
  }, [amount, calcTotal, dataSale, type]);

  const PayPartElement = useMemo(() => {
    return (
      <>
        {type && type !== "Collection" && type !== "Wallet" ? (
          <>
            <Flex
              align="center"
              flexDir="column"
              justifyContent="center"
              mt={3}
              mb={3}
              p={3}
            >
              {dataSale.sale_amount - calcTotal !== 0 &&
              (type === "Invoice" ||
                type === "Sale" ||
                type === "Collection" ||
                type === "Wallet") ? (
                <Button
                  position="relative"
                  onClick={onOpen}
                  borderColor="teal.200"
                  type="submit"
                  bg={colorAlt}
                  color="white"
                  align="center"
                  fontSize={["13px", "13px", "16px"]}
                  p="17px"
                  px={10}
                  mb={6}
                  borderRadius="14px"
                  shadow="base"
                  fontWeight="bold"
                  isLoading={trigger}
                  colorScheme="blue"
                  spinner={<BeatLoader size={8} color="teal" />}
                  _hover={{
                    bg: "white",
                    color: "#2fb5b0",
                  }}
                  _active={{
                    bg: "white",
                    color: "#2fb5b0",
                  }}
                >
                  {type !== "Collection" || type !== "Wallet"
                    ? `Click to Pay: ${dataSale?.sale_amount - calcTotal}`
                    : "Click to contribute"}
                </Button>
              ) : (
                <></>
              )}

              <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                size="md"
                display="flex"
                alignItems="center"
                flexDir="column"
              >
                {!subActive || payLink === undefined ? (
                  <>
                    <ModalOverlay />
                    <ModalContent>
                      <ModalCloseButton />
                      <ModalBody p={10} fontSize="2xl">
                        <Text>
                          Payments are disabled for{" "}
                          <span
                            style={{ color: "#2FB5B0", fontWeight: "bold" }}
                          >
                            {data?.data.saledata.org_data.name}
                          </span>
                          . <br /> Please contact the owner for support.
                        </Text>
                      </ModalBody>
                    </ModalContent>
                  </>
                ) : (
                  <>
                    <ModalOverlay />
                    {
                      <ModalContent>
                        <ModalHeader position="relative">
                          <Image src={mpesa} alt="MPESA" />
                        </ModalHeader>

                        <ModalCloseButton />
                        <ModalBody pb={6}>
                          <FormControl isRequired fontSize="2xl">
                            <FormLabel fontSize="xl">
                              A payment request shall be sent to this number
                            </FormLabel>
                            <br />

                            <FormLabel fontSize="xl" fontWeight="bold">
                              &nbsp; Name
                            </FormLabel>
                            <Input
                              borderRadius="0px"
                              backgroundColor="white"
                              border="1px solid gray"
                              borderColor="black"
                              borderRight="0px"
                              borderLeft="0px"
                              borderTop="0px"
                              mb="12px"
                              type="text"
                              placeholder={!name ? "Enter name here" : ""}
                              fontSize="2xl"
                              size="lg"
                              autoComplete="off"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              required
                              _hover={{
                                outline: "none",
                              }}
                              _active={{
                                bg: "white",
                                color: "#2fb5b0",
                                outline: "none",
                              }}
                            />

                            <FormLabel fontSize="xl" fontWeight="bold">
                              &nbsp; Phone number
                            </FormLabel>
                            <Input
                              borderRadius="0px"
                              backgroundColor="white"
                              border="1px solid gray"
                              borderColor="black"
                              borderRight="0px"
                              borderLeft="0px"
                              borderTop="0px"
                              mb="12px"
                              type="tel"
                              placeholder={!number ? "Enter number here" : ""}
                              fontSize="2xl"
                              size="lg"
                              autoComplete="off"
                              onChange={(e) => handleInput(e, setPhoneLength, setNumber)}
                              value={number}
                              required
                              _hover={{
                                outline: "none",
                              }}
                              _active={{
                                bg: "white",
                                color: "#2fb5b0",
                                outline: "none",
                              }}
                            />

                            <FormLabel fontSize="xl" fontWeight="bold">
                              &nbsp; Amount
                            </FormLabel>
                            <Input
                              borderRadius="0px"
                              backgroundColor="white"
                              border="1px solid gray"
                              borderColor="black"
                              borderRight="0px"
                              borderLeft="0px"
                              borderTop="0px"
                              mb="12px"
                              fontSize="2xl"
                              type="number"
                              ref={inputRef}
                              size="lg"
                              autoComplete="off"
                              placeholder={!amount ? "Enter amount here" : ""}
                              onChange={(e) => setAmount(e.target.value)}
                              value={amount}
                              required
                              _hover={{
                                outline: "none",
                              }}
                              _active={{
                                bg: "white",
                                color: "#2fb5b0",
                                outline: "none",
                              }}
                            />
                          </FormControl>
                        </ModalBody>

                        <ModalFooter
                          align="center"
                          justify="center"
                          display="flex"
                          flexDir="column"
                        >
                          <Button
                            border="2px solid teal"
                            onClick={handlePayment}
                            borderColor="teal.200"
                            type="submit"
                            bg="white"
                            color="#2fb5b0"
                            align="center"
                            fontSize={["12px", "12px", "15px"]}
                            p="15px"
                            letterSpacing="wider"
                            borderRadius="18px"
                            boxShadow="base"
                            fontWeight="bold"
                            isDisabled={
                              number?.length === phoneLength &&
                              amount > 0 &&
                              name
                                ? false
                                : true
                            }
                            mb={6}
                            isLoading={trigger}
                            spinner={<BeatLoader size={8} color="teal" />}
                            _hover={{
                              bg: "teal.100",
                            }}
                            _active={{
                              bg: "white",
                              color: "#2fb5b0",
                            }}
                          >
                            Pay Now:{" "}
                            <span style={{ color: "#062a30" }}>
                              &nbsp; {amount}
                            </span>
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    }
                  </>
                )}
              </Modal>

              <Button
                color={colorAlt}
                variant="outline"
                borderColor="teal.200"
                onClick={handleGeneratePdfDocument}
                borderRadius="14px"
                p="17px"
                isLoading={clicked}
                loadingText="generating pdf"
                fontSize={["12px", "13px", "16px"]}
                fontWeight="bold"
                _hover={{
                  bg: "#2fb5b0",
                  color: "white",
                }}
                _active={{
                  bg: "white",
                  color: "#2fb5b0",
                }}
              >
                <Text mt={4}>
                  <Icon as={FaDownload} />
                </Text>
                <Text mt={4}>
                  &nbsp; Download PDF
                </Text>
              </Button>
            </Flex>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }, [
    name,
    amount,
    number,
    isOpen,
    onClose,
    onOpen,
    trigger,
    handlePayment,
    payLink,
  ]);

  return PayPartElement;
}
