import React, { useEffect, useState } from "react";
import "../index.css";
import { useParams } from "react-router-dom";
import { useDisclosure, Flex, Image } from "@chakra-ui/react";
import logo from "../assets/img/fullBlack.webp";
import paid from "../assets/img/paids.webp";
import mpesa from "../assets/img/mpesao.webp";
import jpg from "../assets/img/qw.webp";
import logos from "../assets/img/logo.webp";
import bg from "../assets/img/main-bg.webp";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { timer } from "../constants/variables";

import LoadingPage from "../components/LoadingPage";
import {
  titleCase,
  handleInput,
  handleInputPledge,
  handleInputChange1,
  handleInputChange2,
  handleInputChange3,
  downloadPdf,
  handleCloseThisModal,
  handleCardClick,
  extractData,
} from "../utils/functions";
import { PayPart } from "./Features/PayModal";
import {
  checkName,
  checkNameSurvey,
  checkPledge,
  checkPledgeName,
  getMyImages,
  handleAsync,
  handleGeneratePdf,
  handleGetPdfDetails,
  handlePay,
  handlePledge,
  handleRecheck,
} from "../utils/api";
import CollectPage from "./CollectPage";
import FourOFour from "./404";
import Advanced from "./Templates/Advanced";
import Standard from "./Templates/Standard";
import Default from "./Templates/Default";

TimeAgo.addLocale(en);

const PageWrapper = () => {
  const { trackCode, urlParams } = useParams();
  let dString;

  if (urlParams) {
    try {
      dString = atob(urlParams).split("/");
    } catch (error) {
      dString = [];
    }
  } else {
    dString = [];
  }

  // const urlOption = dString && dString[0];
  // const urlAmount = dString && dString[1];
  // const urlPhone = dString && dString[2];
  const urlParamsValues = {};

  if (dString.length > 0) {
    dString.forEach((param, index) => {
      if (/^(254\d{9}|07\d{8}|\+254\d{10})$/.test(param)) {
        urlParamsValues[`urlPhone`] = param;
      } else if (/^\d+$/.test(param) && parseInt(param) <= 500000) {
        urlParamsValues[`urlAmount`] = param;
      } else {
        urlParamsValues[`urlOption`] = param;
      }
    });
  }

  let urlAmount = urlParamsValues?.urlAmount;
  let urlOption = urlParamsValues?.urlOption;

  // console.log(urlParamsValues);
  let code = trackCode.toUpperCase();
  const printRef = React.useRef();

  const [type, setType] = useState("");
  const [org_id, setOrgId] = useState("");
  const [sales_code, setSalesCode] = useState("");
  const [paylink_id, setPayLinkId] = useState("");
  const [original_date, setOriginalDate] = useState("");
  const [pay_account_details, setPayAccountDetails] = useState(null);
  const [isShare, setIsShare] = useState(false);
  const [copied, setCopied] = useState(false);
  const [imgUri, setImgUri] = useState();
  const [disabled, setDisabled] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (urlParamsValues?.urlAmount) {
      onOpen();
    }
  }, [urlParamsValues?.urlAmount, onOpen]);

  let [data, setData] = useState();
  let [load, setLoad] = useState(false);
  let [trigger, setTrigger] = useState(false);
  const [opening, setOpening] = useState(false);

  const [name, setName] = useState();
  const [recurring, setRecurring] = useState(false);
  const [number, setNumber] = useState();
  const [time, setTime] = useState();
  const [amount, setAmount] = useState(0.0);
  const [image, setImage] = useState();
  const [buttone, setButtone] = useState();
  const [nameOption, setNameOption] = useState();
  const [loadDocs, setLoadDocs] = useState(true);
  const [paymentData, setPaymentData] = useState();
  const [payLink, setPayLink] = useState();
  const [clicked, setClicked] = useState(false);
  const [subActive, setSubActive] = useState(true);
  const [memberPay, setMemberPay] = useState();
  const [subscribeData, setSubscribeData] = useState();
  const [survey, setSurvey] = useState(false);
  const [contactId, setContactId] = useState();

  const [selection, setSelection] = useState();

  const [pledgeAmount, setPledgeAmount] = useState();
  const [pledgeNumber, setPledgeNumber] = useState();
  const [customerName, setCustomerName] = useState();

  const dataSale = data?.data?.saledata;

  const [docImageUrl, setDocImageUrl] = useState();
  const [docTemplate, setDocTemplate] = useState();
  const [docHexCode, setDocHexCode] = useState();
  const [docTermsService, setDocTermsService] = useState("default");
  const [isToOpen, setIsToOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paidBy, setPaidBy] = useState("");
  const [phoneNumberBy, setPhoneNumberBy] = useState("");
  const [transactionCode, setTransactionCode] = useState("");
  const [updating, setIsUpdating] = useState(false);
  const [phoneLength, setPhoneLength] = useState(10);
  const [urlOptions, setUrlOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [payType, setPayType] = useState("mpesa");
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [verify, setVerify] = useState(true);
  const [saleId, setSaleId] = useState();
  const [other_details, setOtherDetails] = useState();
  const [selection_details, setSelectionDetails] = useState();

  const components = {
    advanced: Advanced,
    standard: Standard,
    default: Default,
  };

  const Invoice =
    components[docTemplate?.toLowerCase()] || components["default"];

  setTimeout(() => {
    setTime(0);
  }, 60000);

  const options = {
    autoClose: 500,
  };

  const buttonDisable = (button, selection) => {
    if (button) {
      if (selection) {
        return true;
      } else return false;
    } else return true;
  };

  useEffect(() => {
    if (copied) {
      toast.success("copied to clipboard", options);
      setTimeout(function () {
        setCopied(false);
      }, 500);
    }
  }, [copied]);

  useEffect(() => {
    trackCode &&
      handleAsync(
        code,
        setLoad,
        setTrigger,
        setData,
        setAmount,
        setNumber,
        setName,
        setImage,
        setButtone,
        setNameOption,
        setPaymentData,
        setPayLink,
        setSubActive,
        setPhoneLength,
        urlOption,
        urlAmount,
        setSelection,
        setUrlOptions,
        setPledgeAmount,
        setSaleId,
        setDefaultOptions,
        setSubscribeData,
        setMemberPay,
        setPayAccountDetails
      );
  }, [trackCode]);

  useEffect(() => {
    if (dataSale && data) {
      const { type, org_id, sales_code, paylink_id, original_date } =
        extractData(dataSale, data);
      setType(type);
      setOrgId(org_id);
      setSalesCode(sales_code);
      setPayLinkId(paylink_id);
      setOriginalDate(original_date);
    }
  }, [dataSale, data]);

  useEffect(() => {
    org_id && getMyImages(org_id, setImgUri);
  }, [org_id]);

  useEffect(() => {
    org_id &&
      handleGetPdfDetails(
        org_id,
        setDocImageUrl,
        setDocTemplate,
        setDocHexCode,
        setDocTermsService,
        setLoadDocs
      );
  }, [org_id]);

  useEffect(() => {
    org_id &&
      (type === "Collection" || type === "Wallet") &&
      !survey &&
      number?.length === phoneLength &&
      checkName(org_id, number, setName, nameOption, setDisabled);
  }, [number, org_id]);

  useEffect(() => {
    org_id &&
      (type === "Collection" || type === "Wallet") &&
      survey &&
      number?.length === phoneLength &&
      checkNameSurvey(org_id, number, setName, setDisabled, setContactId);
  }, [number, org_id]);

  useEffect(() => {
    org_id &&
      (type === "Collection" || type === "Wallet") &&
      pledgeNumber?.length === phoneLength &&
      checkPledgeName(org_id, pledgeNumber, setCustomerName, setDisabled);
  }, [pledgeNumber, org_id]);

  const handlePayment = () => {
    handlePay(
      org_id,
      sales_code,
      amount,
      number,
      paymentData,
      name,
      recurring,
      selection,
      type,
      timer,
      setTrigger,
      toast,
      onClose,
      handlePaymentRecheck,
      payType,
      setCheckoutUrl,
      verify,
      setVerify,
      saleId,
      other_details,
      selection_details,
      pay_account_details
    );
  };

  const handlePaymentRecheck = () => {
    handleRecheck(
      org_id,
      number,
      setPaymentStatus,
      setPaidBy,
      setPhoneNumberBy,
      setTransactionCode,
      setAmount,
      amount,
      setIsToOpen,
      setIsUpdating,
      setVerify
    );
  };

  const handleRepay = () => {
    setIsToOpen(false);
    setPaymentStatus(false);
    setIsUpdating(false);
    setIsToOpen(false);
    setTrigger(false);
    onOpen();
  };

  const handlePledgeContribution = () => {
    handlePledge(
      org_id,
      sales_code,
      pledgeNumber,
      customerName,
      pledgeAmount,
      selection,
      toast,
      setTrigger,
      timer
    );
  };

  const handleGeneratePdfDocument = () => {
    handleGeneratePdf(
      docTemplate,
      dataSale,
      docTermsService,
      docHexCode,
      docImageUrl,
      downloadPdf,
      setClicked,
      data,
      paymentData,
      original_date,
      toast
    );
  };

  const payProps = (
    <PayPart
      handleInputChange3={handleInputChange3}
      handleInputChange2={handleInputChange2}
      subActive={subActive}
      setAmount={setAmount}
      amount={amount}
      clicked={clicked}
      setClicked={setClicked}
      data={data}
      handleGeneratePdf={handleGeneratePdf}
      handleInputChange1={handleInputChange1}
      handlePay={handlePay}
      isOpen={isOpen}
      number={number}
      onClose={onClose}
      onOpen={onOpen}
      trigger={trigger}
      type={type}
      dataSale={dataSale}
      name={name}
      payLink={payLink}
      phoneLength={phoneLength}
      setPhoneLength={setPhoneLength}
      setNumber={setNumber}
      setName={setName}
    />
  );

  return load ? (
    time === 0 ? (
      <FourOFour />
    ) : (
      <LoadingPage />
    )
  ) : data ? (
    !loadDocs && type && type !== "Collection" && type !== "Wallet" ? (
      <Invoice
        docTemplate={docTemplate}
        type={type}
        dataSale={dataSale}
        docTermsService={docTermsService}
        docImageUrl={docImageUrl}
        original_date={original_date}
        amount={amount}
        phoneLength={phoneLength}
        clicked={clicked}
        setClicked={setClicked}
        setAmount={setAmount}
        data={data}
        subActive={subActive}
        handleGeneratePdfDocument={handleGeneratePdfDocument}
        handlePayment={handlePayment}
        isOpen={isOpen}
        name={name}
        number={number}
        onClose={onClose}
        onOpen={onOpen}
        trigger={trigger}
        payLink={payLink}
        docHexCode={docHexCode}
        paymentData={paymentData}
        logo={logo}
        paid={paid}
        bg={bg}
        payPart={payProps}
      />
    ) : (
      <Flex
        // className={
        //   type && (type === "Collection" || type === "Wallet") && "trythis"
        // }
        minH="100vh"
        maxW="100%"
        overflow="auto"
        boxSizing="border-box"
        justifyContent="center"
        alignItems="center"
      >
        <div
          className={
            type && (type === "Collection" || type === "Wallet")
              ? "containerxz"
              : "container"
          }
        >
          <ToastContainer zIndex="10001" />
          <div className="row this">
            <div
              className={
                type && (type === "Collection" || type === "Wallet")
                  ? "fle"
                  : "receipt-main col-xs-10 col-sm-10 col-md-6 col-xs-offset-1 col-sm-offset-1 col-md-offset-3"
              }
              ref={printRef}
              position={
                type && (type === "Collection" || type === "Wallet")
                  ? "relative"
                  : ""
              }
              id="contentToPrint"
            >
              {type && (type === "Collection" || type === "Wallet") && (
                <Image
                  src={jpg}
                  position="fixed"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  zIndex="-100"
                  objectFit="cover"
                  opacity=".05"
                />
              )}

              {type && (type === "Collection" || type === "Wallet") && (
                <CollectPage
                  dataSale={dataSale}
                  jpg={jpg}
                  logos={logos}
                  setIsShare={setIsShare}
                  isShare={isShare}
                  data={data}
                  type={type}
                  mpesa={mpesa}
                  number={number}
                  handleInput={handleInput}
                  nameOption={nameOption}
                  phoneLength={phoneLength}
                  disabled={disabled}
                  setName={setName}
                  setRecurring={setRecurring}
                  recurring={recurring}
                  setCustomerName={setCustomerName}
                  setSelection={setSelection}
                  amount={amount}
                  opening={opening}
                  subActive={subActive}
                  payLink={payLink}
                  customerName={customerName}
                  buttonDisable={buttonDisable}
                  buttone={buttone}
                  selection={selection}
                  setPledgeAmount={setPledgeAmount}
                  pledgeAmount={pledgeAmount}
                  handlePledgeContribution={handlePledgeContribution}
                  pledgeNumber={pledgeNumber}
                  isToOpen={isToOpen}
                  handleCloseThisModal={handleCloseThisModal}
                  setIsToOpen={setIsToOpen}
                  updating={updating}
                  paymentStatus={paymentStatus}
                  titleCase={titleCase}
                  paidBy={paidBy}
                  phoneNumberBy={phoneNumberBy}
                  transactionCode={transactionCode}
                  handleCardClick={handleCardClick}
                  handlePaymentRecheck={handlePaymentRecheck}
                  handleRepay={handleRepay}
                  onOpen={onOpen}
                  trigger={trigger}
                  name={name}
                  isOpen={isOpen}
                  trackCode={trackCode}
                  original_date={original_date}
                  onClose={onClose}
                  imgUri={imgUri}
                  setAmount={setAmount}
                  handlePayment={handlePayment}
                  setOpening={setOpening}
                  handleInputPledge={handleInputPledge}
                  setPhoneLength={setPhoneLength}
                  setPledgeNumber={setPledgeNumber}
                  setNumber={setNumber}
                  urlOption={urlParamsValues?.urlOption}
                  urlAmount={urlParamsValues?.urlAmount}
                  urlPhone={urlParamsValues?.urlPhone}
                  urlOptions={urlOptions}
                  defaultOptions={defaultOptions}
                  setPayType={setPayType}
                  payType={payType}
                  checkoutUrl={checkoutUrl}
                  setCheckoutUrl={setCheckoutUrl}
                  setVerify={setVerify}
                  verify={verify}
                  paymentData={paymentData}
                  downloadPdf={downloadPdf}
                  toast={toast}
                  setOtherDetails={setOtherDetails}
                  setSelectionDetails={setSelectionDetails}
                  saleId={saleId}
                  memberPay={memberPay}
                  subscribeData={subscribeData}
                  sales_code={sales_code}
                  org_id={org_id}
                  survey={survey}
                  setSurvey={setSurvey}
                  contactId={contactId}
                />
              )}
            </div>
          </div>
        </div>
      </Flex>
    )
  ) : (
    <LoadingPage />
  );
};

export default PageWrapper;
