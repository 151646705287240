import React from "react";
import { VStack, Text } from "@chakra-ui/react";

const PaymentConfirmation = ({ title, content }) => {
  return (
    <VStack px={0} w="100%" pt={2} mb={3} justifyContent="center" gap={0}>
      <Text m={0} p={0} color="gray">
        {title}
      </Text>
      <Text m={0} p={0} fontWeight="bold" fontSize="3xl">
        {content}
      </Text>
    </VStack>
  );
};

export default PaymentConfirmation;
