import {
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
  Input,
} from "@chakra-ui/react";
import { colorMain } from "../../constants/themes";
import DynamicButton from "../Features/DynamicButton";
import DynamicDrawer from "../Features/DynamicDrawer";
import ConfirmModal from "../Features/ConfirmModal";
import Truncate from "../Features/Truncate";
import Ticket from "../../assets/img/Ticket.svg";
import Rent from "../../assets/img/Rent.svg";
import React, { useState } from "react";
import { renderMessageWithLinksDesc } from "../../utils/functions";
import Moment from "react-moment";
import DynamicSelectInput, {
  DynamicSelectInputEvents,
} from "../Features/DynamicSelect";
import { useEffect } from "react";

const EventsTemplate = ({
  data,
  type,
  trigger,
  toast,
  trackCode,
  number,
  commonProps,
  opening,
  setOpening,
  verify,
  isToOpen,
  setIsToOpen,
  handleCloseThisModal,
  updating,
  paymentStatus,
  amount,
  paidBy,
  phoneNumberBy,
  transactionCode,
  handleCardClick,
  handlePaymentRecheck,
  handleRepay,
  selection,
  name,
  payType,
  dataSale,
  downloadPdf,
  callendar,
  location,
  template,
  urlOptions,
  setSelection,
  selectedOption,
  setAmount,
  venue,
}) => {
  const [period, setPeriod] = useState(1);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [detail, setDetail] = useState(
    data?.data?.saledata?.contact_detail || ""
  );

  const [defaultAmt, setDefaultAmt] = useState(amount);

  useEffect(() => {
    const regex = /{([^}]+)}/;

    const match = detail?.match(regex);

    if (match) {
      const ven = match[1];
      const updatedContactDetail = detail?.replace(`{${ven}}`, "");
      setDetail(updatedContactDetail);
      // console.log(detail);
    }
  }, [detail]);

  const handleIncrement = () => {
    if (period < 9999) {
      setPeriod(period + 1);
    }
  };

  const handleDecrement = () => {
    if (period > 1) {
      setPeriod(period - 1);
    }
  };

  const targetsJson = data?.data?.saledata?.sale_settings?.pay_details_targets;

  const processedJson = targetsJson?.replace(/'/g, '"');

  let targets;
  try {
    targets = JSON.parse(processedJson)?.targets || [];
  } catch (error) {
    console.error("Error parsing JSON:", error);
    targets = [];
  }

  // console.log(targets);

  // State to track selected target
  const [selectedTarget, setSelectedTarget] = useState(null);

  useEffect(() => {
    if (selection) {
      const selectedTarget = targets.find(
        (target) => target.name === selection
      );
      setSelectedTarget(selectedTarget);
    } else {
      setSelectedTarget(null);
    }
  }, [selection]);

  useEffect(() => {
    if (defaultAmt > 0 && selection) {
      setAmount(defaultAmt * (period || 1));
      setSelectedTarget({ name: selection, amount: defaultAmt });
      return;
    }
    if (selectedTarget && selectedTarget.amount) {
      setAmount(selectedTarget.amount * (period || 1));
    } else if (selectedTarget && !selectedTarget.amount) {
      setAmount("");
    }
  }, [selectedTarget, period, defaultAmt]);

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setPeriod(value);
    }
  };

  return (
    <Flex
      flexDir="column"
      color="gray.600"
      w="100%"
      p={4}
      py={3}
      mt={0}
      pt={0}
      justifyContent="space-between"
    >
      {(data?.data.saledata?.sale_settings?.add_about_collection === null ||
        data?.data.saledata?.sale_settings?.add_about_collection) &&
        type !== "Wallet" && (
          <Flex flexDirection="column">
            <Text fontWeight="medium">
              {/\bhttps?:\/\/\S+\b/.test(detail) ||
              /<[a-z][\s\S]*>/i.test(detail)
                ? renderMessageWithLinksDesc(detail)
                : detail !== undefined && (
                    <Truncate text={detail} maxLength={24} />
                  )}
            </Text>
          </Flex>
        )}

      <Flex
        alignItems="center"
        justifyContent="flex-start"
        gap={20}
        mb={6}
        pr={5}
      >
        <Flex height="fit-content" py={0} pt={2}>
          <Image
            src={callendar}
            objectFit="contain"
            aspectRatio="16/9"
            w="30px"
            ml={3}
            opacity="1"
            alt=""
          />
          <Flex
            flexDir="column"
            fontWeight="light"
            justifyContent="center"
            p={0}
            fontSize="xl"
            gap={0}
            ml={2}
            color="gray.600"
          >
            <Text color={colorMain} fontWeight="semibold" m={0}>
              Date
            </Text>
            <Moment
              format="dddd MMM D"
              date={
                new Date(
                  (data?.data.saledata.sales_date).toString().length === 16
                    ? data?.data.saledata.sales_date / 1000
                    : (data?.data.saledata.sales_date).toString().length === 19
                    ? data?.data.saledata.sales_date / 1000000
                    : (data?.data.saledata.sales_date).toString().length > 10
                    ? data?.data.saledata.sales_date
                    : data?.data.saledata.sales_date * 1000
                )
              }
            />
          </Flex>
        </Flex>
        {venue && (
          <Flex
            flexDir="column"
            fontWeight="light"
            justifyContent="center"
            p={0}
            fontSize="xl"
            gap={0}
            color="gray.600"
          >
            <Flex height="fit-content" py={0} pt={2}>
              <Image
                src={location}
                objectFit="contain"
                aspectRatio="16/9"
                w="30px"
                opacity="1"
                alt=""
              />
              <Flex
                flexDir="column"
                justifyContent="center"
                p={0}
                gap={0}
                ml={2}
                color={colorMain}
              >
                <Text color={colorMain} fontWeight="semibold" m={0}>
                  Venue
                </Text>
                <Text m={0} color="gray.600" fontWeight="light">
                  {venue}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>

      <Flex shadow="base" flexDir="column" mt={2} mb={2}>
        <Flex p={2}>
          <Text
            pl={4}
            // bg="blackAlpha.100"
            fontSize="xl"
            fontWeight="semibold"
            w="100%"
            color="gray.500"
          >
            Ticket
          </Text>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          px={2}
        >
          <Flex>
            <Flex p={2}>
              <Image
                src={Ticket}
                objectFit="contain"
                aspectRatio="16/9"
                w="25px"
                opacity="1"
                alt=""
              />
            </Flex>
            <Flex flexDir="column" fontWeight="semibold" gap={0}>
              <Text fontWeight="light" m={0} p={0}>
                Category
              </Text>
              <DynamicSelectInputEvents
                options={urlOptions}
                condition={selectedOption}
                value={selection}
                onChange={(e) => setSelection(e.target.value)}
                required
              />
            </Flex>
          </Flex>

          {selectedTarget && amount > 0 && (
            <Flex flexDir="column">
              <Text fontWeight="lighter">Price</Text>
              <Flex pb={1}>{amount}</Flex>
            </Flex>
          )}

          {selectedTarget?.amount > 0 && (
            <Flex flexDir="column">
              <Text fontWeight="lighter">Quantity</Text>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                gap=".1rem"
              >
                <Button
                  size="sm"
                  onClick={handleDecrement}
                  fontWeight="light"
                  fontSize="5xl"
                  color={colorMain}
                  bg="#e9e9e9"
                  p={3}
                  pb={5}
                  alignItems="center"
                  borderLeftRadius="none"
                  justifyContent="center"
                >
                  -
                </Button>
                <Input
                  type="number"
                  fontSize="2xl"
                  fontWeight="semibold"
                  px={1}
                  mb={1}
                  variant="unstyled"
                  value={period}
                  onChange={handleChange}
                  min={0}
                  style={{
                    width: `${period?.toString().length * 12}px`,
                    ...(period?.toString().length > 2 && {
                      width: `${period?.toString().length * 10}px`,
                    }),
                    ...(period?.toString().length === 1 && {
                      width: `${period?.toString().length * 16}px`,
                    }),
                  }}
                />
                {/* <Text fontSize="2xl" fontWeight="bold" px={1} mb={1}>
                  {period}
                </Text> */}
                <Button
                  size="sm"
                  onClick={handleIncrement}
                  fontWeight="lighter"
                  fontSize="5xl"
                  color={colorMain}
                  bg="#e9e9e9"
                  p={3}
                  pb={5}
                  alignItems="center"
                  borderLeftRadius="none"
                  justifyContent="center"
                >
                  +
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex
          // align="center"
          flexDir="column"
          // justifyContent="center"
          w="100%"
          mt={5}
          mb={0}
        >
          {type === "Invoice" ||
          type === "Sale" ||
          type === "Collection" ||
          type === "Wallet" ? (
            <Flex flexDirection="column" p={2}>
              {data?.data.saledata?.sale_settings
                ?.contribute_button_visible && (
                <DynamicButton
                  onClickHandler={onOpen}
                  buttonText={`${
                    data?.data.saledata?.sale_settings
                      ?.contribute_button_name || "Pay"
                  }`}
                  isDisabled={!selection}
                  isLoading={trigger}
                  colorMain={colorMain}
                />
              )}
            </Flex>
          ) : (
            <></>
          )}

          <DynamicDrawer
            isOpen={isOpen}
            onClose={onClose}
            contributionMode="pay"
            selectedTarget={selectedTarget && selectedTarget}
            buttonText={
              data?.data.saledata?.sale_settings?.contribute_button_name ||
              "click to pay"
            }
            {...commonProps}
          />

          <DynamicDrawer
            isOpen={opening}
            onClose={() => setOpening(false)}
            contributionMode="pledge"
            buttonText={
              data?.data.saledata?.sale_settings?.pledge_button_name ||
              "Make a Pledge"
            }
            {...commonProps}
          />
          {!verify && (
            <ConfirmModal
              isToOpen={isToOpen}
              setIsToOpen={setIsToOpen}
              handleCloseThisModal={handleCloseThisModal}
              updating={updating}
              paymentStatus={paymentStatus}
              amount={amount}
              paidBy={paidBy}
              phoneNumberBy={phoneNumberBy}
              transactionCode={transactionCode}
              colorMain={colorMain}
              handleCardClick={handleCardClick}
              handlePaymentRecheck={handlePaymentRecheck}
              handleRepay={handleRepay}
              selection={selection}
              enabled={
                data &&
                data?.data.saledata?.sale_settings?.download_receipt_visible
              }
              name={name}
              orgName={
                data && (data?.data.saledata.contact_name || "Anonymous")
              }
              number={number}
              customMessage={
                data && data?.data.saledata?.sale_settings?.customer_message
              }
              trackCode={trackCode}
              payType={payType}
              downloadMessage={
                data &&
                data?.data.saledata?.sale_settings?.download_receipt_name
              }
              details={dataSale}
              downloadPdf={downloadPdf}
              toast={toast}
            />
          )}
        </Flex>
      </Flex>

      <Flex w="100%" alignItems="center" justifyContent="center">
        {!data?.data.saledata.org_data.name && (
          <Flex
            alignItems="center"
            color="gray.500"
            letterSpacing={1}
            fontSize="13"
            fontWeight="bold"
          >
            <Text display="flex" alignItems="center" my={2} mt={1}>
              &nbsp; Contact: {data?.data.saledata.org_data.name || "Anonymous"}
              {`, ${data?.data.saledata.org_data.main_phone_number || ""}`}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EventsTemplate;
