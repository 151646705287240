import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Text,
  Flex,
  HStack,
  FormLabel,
  Input,
  useRadioGroup,
  FormControl,
  Heading,
  FormHelperText,
  Tooltip,
  Button,
  Icon,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import { RadioCard } from "./RadioOption";
import { ActionButton, PayButton } from "./DynamicButton";
import FadeIn from "react-fade-in/lib/FadeIn";
import DynamicInput from "./DynamicInput";
import DynamicSelectInput from "./DynamicSelect";
import { Checkbox } from "@chakra-ui/react";
import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { HiPlusCircle } from "react-icons/hi";
import { CloseIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import PledgePopUp from "./PledgePopUp";
import { checkPledge, checkSub } from "../../utils/api";
import SubPopUp from "./SubPopUp";
import OptionsWithCheckboxes from "./OptionsWithCheckboxes";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    padding: 30,
    borderRadius: 0,
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });

const DynamicDrawer = React.memo(
  ({
    placement = "bottom",
    isOpen,
    onClose,
    isCentered,
    subActive,
    payLink,
    data,
    colorMain,
    amount,
    setAmount,
    mpesa,
    number,
    phoneLength,
    name,
    setName,
    setRecurring,
    recurring,
    disabled,
    selection,
    setSelection,
    buttone,
    buttonDisable,
    trigger,
    handlePayment,
    nameOption,
    handleInput,
    contributionMode,
    setOpening,
    pledgeNumber,
    customerName,
    setCustomerName,
    pledgeAmount,
    setPledgeAmount,
    handlePledgeContribution,
    handleInputPledge,
    setPhoneLength,
    setPledgeNumber,
    setNumber,
    buttonText,
    selectedOption,
    enteredAmount,
    urlOptions,
    setPayType,
    payType,
    paymentData,
    setOtherDetails,
    setSelectionDetails,
    selectedTarget,
    trackCode,
    saleId,
    defaultOptions,
    memberPay,
    subscribeData,
    sales_code,
    org_id,
  }) => {
    const templateType =
      data?.data.saledata?.sale_settings?.template_type || "default";

    let detailTo =
      data?.data?.saledata?.sale_settings?.pay_detail_type || false;
    // let detailTo = "options";

    const getActionWord = (templateType) =>
      templateType === "realestate"
        ? "Pay"
        : templateType === "events"
        ? "Pay"
        : templateType === "tickets"
        ? "Pay"
        : templateType === "fundraiser"
        ? "Contribute"
        : templateType === "business"
        ? "Pay"
        : templateType === "donations"
        ? "Donate"
        : templateType === "chamas"
        ? "Contribute"
        : "Contribute";

    const getPledgeWord = (templateType) => {
      switch (templateType) {
        case "realestate":
          return "Payment Due";
        case "events":
          return "Payment Due";
        case "tickets":
          return "Reserved Tickets";
        case "business":
          return "Payment Due";
        case "fundraiser":
          return "Pledge Due";
        case "donations":
          return "Pledge Due";
        case "chamas":
          return "Loan Due";
        case "wedding":
        case "tripsandtravel":
        case "funeral":
        case "grouppayments":
          return "Pledge Due";
        default:
          return "Pledge Due";
      }
    };

    let pledgeWord = getPledgeWord(templateType);

    const initialRef = useRef();
    const testRef = useRef();
    const [isChecked, setIsChecked] = useState(false);
    const [cardPayment, setCardPayment] = useState(false);
    const [counter, setCounter] = useState(0);
    const [totalSplitAmount, setTotalSplitAmount] = useState(0);

    const [pledge, setPledge] = useState(0);
    const [memberSub, setMemberSub] = useState(false);
    const [isSubLoading, setIsSubLoading] = useState(false);

    let cardOption = true;
    const [isPledgeOpen, setIsOpenPledge] = useState(false);
    const [isSubOpen, setIsOpenSub] = useState(false);
    const [isPopped, setIsPopped] = useState(false);
    const subAmount = data?.data.saledata?.sale_settings?.subscribe_amount || 0;

    const [inputs, setInputs] = useState([]);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
    const [isSelectButtonDisabled, setIsSelectButtonDisabled] = useState(false);
    const [totalSplitAmountB, setTotalSplitAmountB] = useState(0);
    const [isCheckedAanyChecked, setAnyChecked] = useState(false);

    useEffect(() => {
      number?.length === phoneLength &&
        parseInt(subAmount) > 0 &&
        memberPay &&
        inputs.length === 0 &&
        checkFilter();
    }, [number]);

    let checkFilter = async () => {
      await checkSub(number, setMemberSub, sales_code, setIsSubLoading);
      memberPay && !memberSub && setIsOpenSub(true);
    };
    useEffect(() => {
      (subActive || memberSub) &&
        name &&
        name.length > 2 &&
        inputs.length === 0 &&
        checkPledge(saleId, name, setPledge);
    }, [name]);

    const addInput = () => {
      setCounter((prevCounter) => prevCounter + 1);
      setInputs((prevInputs) => [...prevInputs, { name: "", amount: "" }]);
    };

    const handleInputChange = (e, index, type) => {
      const { value } = e.target;
      let updatedInputs = [...inputs];

      let intValue = parseInt(value, 10);

      // Update the input value
      updatedInputs[index] = {
        ...updatedInputs[index],
        [type]: type === "amount" ? intValue : value,
      };

      // Calculate the total amount
      const totalAmount = updatedInputs.reduce((sum, input) => {
        return sum + (parseFloat(input.amount) || 0);
      }, 0);

      if (totalAmount <= amount - 1) {
        setInputs(updatedInputs);
        setTotalSplitAmount(totalAmount);
      } else {
        toast.warning(
          "Total split amount must not exceed the amount threshold!"
        );
      }
    };

    useEffect(() => {
      setOtherDetails(inputs);
    }, [inputs]);

    useEffect(() => {
      pledge > 0 && !isPopped && setIsOpenPledge(true) && setIsPopped(true);
    }, [pledge]);

    useEffect(() => {
      const isAnyInputNotFilled = inputs.some(
        (input) => !input.name || !input.amount
      );

      setIsAddButtonDisabled(isAnyInputNotFilled);
    }, [inputs]);

    const removeInput = (index) => {
      setIsAddButtonDisabled(false);
      setCounter((prevCounter) => prevCounter - 1);
      setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
    };

    useEffect(() => {
      const handleResize = () => {
        const viewport = document.querySelector("meta[name=viewport]");
        const initialHeight = window.innerHeight;

        if (window.innerHeight < initialHeight) {
          // Soft keyboard is open, add scroll to the page
          document.documentElement.style.overflow = "auto";

          // Reset the height in meta viewport with the initial height of the page
          viewport.setAttribute(
            "content",
            `height=${initialHeight}px, width=device-width, initial-scale=1.0`
          );
        } else {
          // Soft keyboard is closed, reset meta viewport
          viewport.setAttribute(
            "content",
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          );
        }
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const option = ["mpesa", "card"];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "options",
      value: payType,
    });

    const handleChange = (value) => {
      if (value === "card" && !cardPayment) {
        toast.warning(
          "Organizers have not enabled card payments for this collection, contact them to enable if you prefer to pay with a card"
        );
        return;
      }
      setPayType(value);
    };

    const group = getRootProps();

    const [amountBlank, setAmountBlank] = useState(true);
    const [onPayClick, setOnPayClick] = useState(false);

    useEffect(() => {
      if (onPayClick || amount) {
        if (amount > 0) {
          setAmountBlank(false);
        } else {
          setAmountBlank(true);
          initialRef?.current?.focus();
          toast.warning(`Please enter amount!`);
        }
      } else {
        setAmountBlank(true);
      }
    }, [onPayClick, amount]);

    useEffect(() => {
      setCardPayment(
        paymentData?.find((payment) => payment?.link_type === "CARD")
      );
    }, [paymentData]);

    const targetsJson =
      data?.data?.saledata?.sale_settings?.pay_details_targets;

    // Replace single quotes with double quotes to make the JSON valid
    const processedJson = targetsJson?.replace(/'/g, '"');

    let targets;
    try {
      targets = JSON.parse(processedJson)?.targets || [];
    } catch (error) {
      console.error("Error parsing JSON:", error);
      targets = [];
    }

    // State to track selected target
    const [selectedTargets, setSelectedTargets] = useState(null);

    useEffect(() => {
      if (
        selection &&
        detailTo &&
        detailTo !== "text" &&
        templateType !== "tickets"
      ) {
        const selectedTarget = targets.find(
          (target) => target.name === selection
        );
        setSelectedTargets(selectedTarget || null);
      } else {
        setSelectedTargets(null);
      }
    }, [selection, targets]);

    useEffect(() => {
      if (
        selectedTargets &&
        selectedTargets.amount > 0 &&
        detailTo &&
        detailTo !== "text" &&
        templateType !== "tickets"
      ) {
        contributionMode === "pay" && setAmount(selectedTargets.amount || "");
        contributionMode === "pledge" &&
          setPledgeAmount(
            selectedTargets.amount
              ? selectedTargets.amount
              : pledgeAmount > 0
              ? pledgeAmount
              : ""
          );
      }
    }, [selectedTargets]);

    return (
      <Drawer
        placement={placement}
        isOpen={isOpen}
        onClose={onClose}
        isCentered={isCentered}
        initialFocusRef={initialRef}
        p="2px"
        display="flex"
        alignItems="center"
        flexDir="column"
        closeOnOverlayClick={trigger ? false : true}
      >
        <ToastContainer />
        <DrawerOverlay />
        {contributionMode === "pay" ? (
          <>
            {payLink === undefined ||
            (!subActive && (!memberPay || parseInt(subAmount) < 1)) ? (
              <DrawerContent mb="300">
                <DrawerCloseButton />
                <DrawerBody p={9} fontSize="2xl">
                  <Text>
                    Plan for this collection is not active. The plan has expired
                    or number of contibutors has been exceeded. Contact the
                    organizer to renew or upgrade the plan. We apologize for the
                    inconvenience.
                  </Text>
                </DrawerBody>
              </DrawerContent>
            ) : (
              <>
                <DrawerOverlay />
                <DrawerContent borderTopRadius="20px">
                  <DrawerCloseButton />
                  <DrawerHeader mt={3} textAlign="center" color={colorMain}>
                    Click to switch mode of payment
                    <Flex
                      alignItems="center"
                      pt={3}
                      pb={0}
                      justifyContent="center"
                    >
                      <HStack
                        {...group}
                        variant="outline"
                        outline={`.0082rem solid #bbbbbb`}
                        shadow="base"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        w="100%"
                        height="36px"
                        pt={0}
                        borderRadius="md"
                        px={0}
                        position="relative"
                      >
                        {option.map((value) => {
                          const radio = getRadioProps({ value });
                          return (
                            <RadioCard
                              isDisabled={trigger}
                              key={value}
                              {...radio}
                              onChange={() => handleChange(value)}
                            >
                              {value === "mpesa" ? "MPESA" : "CARD"}
                            </RadioCard>
                          );
                        })}
                      </HStack>
                    </Flex>
                  </DrawerHeader>
                  <DrawerBody pb={0} color={colorMain}>
                    {detailTo === "options" &&
                    amount < 1 &&
                    urlOptions &&
                    urlOptions?.some((option) => option.target > 0) ? (
                      <></>
                    ) : (
                      <FormControl>
                        <Flex
                          // shadow="base"
                          borderRadius="10px"
                          flexDir="column"
                          px={3}
                          pt={4}
                          height="fit-content"
                        >
                          <Text fontWeight="bold" fontSize="xl" pb={0} mb={0}>
                            Enter Amount
                          </Text>
                          <Flex
                            pb={amountBlank ? 0 : 2}
                            alignItems="baseline"
                            ml={
                              !amount || amount?.length < 1
                                ? "12"
                                : amount?.length > 4
                                ? amount?.length - 2
                                : "16"
                            }
                            justifyContent="center"
                            fontWeight="semibold"
                          >
                            <span
                              style={{
                                color: "#878787",
                                fontWeight: "lighter",
                                fontSize: "18px",
                                marginRight: "3px",
                              }}
                            >
                              KES
                            </span>
                            <Input
                              ref={
                                !selectedTarget?.amount > 0
                                  ? initialRef
                                  : testRef
                              }
                              type="number"
                              variant="unstyled"
                              fontSize={["7xl", "7xl"]}
                              placeholder={!amount || amount < 1 ? "0.00" : ""}
                              value={amount}
                              isReadOnly={
                                enteredAmount || selectedTarget?.amount > 0
                                // (amount > 1 &&
                                //   urlOptions &&
                                //   urlOptions.some(
                                //     (option) => option.target > 0
                                //   ))
                              }
                              onChange={(e) => setAmount(e.target.value)}
                              required
                              color={colorMain}
                              fontWeight="semibold"
                              // borderBottom="1px solid black"
                            />
                          </Flex>
                          {amountBlank && (
                            <Flex
                              p={0}
                              pb={2}
                              m={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <FormHelperText
                                p={0}
                                m={0}
                                color="red"
                                fontSize="md"
                              >
                                Amount must be greater than 0.
                              </FormHelperText>
                            </Flex>
                          )}
                          {defaultOptions &&
                            templateType !== "tickets" &&
                            defaultOptions?.length > 0 && (
                              <Flex
                                alignItems="center"
                                justifyContent="center"
                                gap={12}
                              >
                                {defaultOptions?.map((defaultAmount, index) => (
                                  <Button
                                    key={index}
                                    value={defaultAmount}
                                    px={5}
                                    borderRadius={12}
                                    color={
                                      amount === defaultAmount
                                        ? "white"
                                        : `${colorMain}`
                                    }
                                    bg={
                                      amount === defaultAmount
                                        ? `${colorMain}`
                                        : "gray.200"
                                    }
                                    fontSize="xl"
                                    onClick={(e) => {
                                      setAmount(e.target.value);
                                    }}
                                  >
                                    {defaultAmount}
                                  </Button>
                                ))}
                              </Flex>
                            )}
                          {memberPay && !memberSub && isSubOpen && (
                            <SubPopUp
                              isSubOpen={isSubOpen}
                              setIsOpenSub={setIsOpenSub}
                              subAmount={subAmount}
                              number={number}
                              subscribeData={subscribeData}
                              sales_code={sales_code}
                              name={name || "anonymous"}
                              toast={toast}
                              isSubLoading={isSubLoading}
                              setMemberSub={setMemberSub}
                              org_id={org_id}
                              setIsSubLoading={setIsSubLoading}
                            />
                          )}

                          {pledge > 0 && (
                            <Flex
                              p={0}
                              pb={2}
                              m={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <FormHelperText
                                p={0}
                                m={0}
                                fontSize="md"
                                fontWeight="bold"
                              >
                                You have a {pledgeWord?.toLowerCase()} of{" "}
                                <span style={{ color: "red" }}>
                                  KES {pledge}
                                </span>
                              </FormHelperText>
                            </Flex>
                          )}
                          {pledge > 0 && !isPopped && (
                            <PledgePopUp
                              isPledgeOpen={isPledgeOpen}
                              setIsOpenPledge={setIsOpenPledge}
                              pledge={pledge}
                              setAmount={setAmount}
                              pledgeWord={pledgeWord}
                            />
                          )}
                        </Flex>
                      </FormControl>
                    )}
                    {/* {amount && ( */}
                    <FadeIn>
                      <FormControl isRequired fontSize="2xl" mt={10}>
                        <FormLabel fontSize="xl" fontWeight="bold">
                          &nbsp; Phone number
                        </FormLabel>
                        <DynamicInput
                          mb={1}
                          reff={
                            selectedTarget?.amount > 0 ? initialRef : testRef
                          }
                          type="tel"
                          placeholder={!number ? "Enter number here" : ""}
                          value={number}
                          onChange={(e) =>
                            handleInput(
                              e,
                              setPhoneLength,
                              setNumber,
                              payType === "card" && cardOption
                            )
                          }
                        />
                        <FormHelperText
                          textAlign="center"
                          color="orange"
                          fontSize="md"
                          m={0}
                          py={0}
                          mb={8}
                        >
                          {payType === "mpesa"
                            ? "A payment request shall be sent to this number"
                            : "Local card payments are charged 2.9% card processing fee by our card processor Paystack Limited"}
                        </FormHelperText>

                        {nameOption &&
                          (payType === "card" ||
                            number?.length === phoneLength) && (
                            <FadeIn>
                              <FadeIn>
                                <FormLabel fontSize="xl" fontWeight="bold">
                                  &nbsp;
                                  {data?.data.saledata?.sale_settings
                                    ?.name_label || "Name"}
                                </FormLabel>
                                <Flex
                                  shadow="base"
                                  bg="#f4f4f2"
                                  flexDir="column"
                                  p={3}
                                  py={5}
                                  mt={3}
                                >
                                  <Flex
                                    w="100%"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    mb={counter > 0 && 4}
                                  >
                                    <DynamicInput
                                      w={counter > 0 ? "75%" : "100%"}
                                      type="text"
                                      placeholder={
                                        !name ? "Enter details here" : ""
                                      }
                                      value={isChecked ? "Anonymous" : name}
                                      onChange={(e) => setName(e.target.value)}
                                      isDisabled={disabled}
                                    />
                                    {counter > 0 && (
                                      <Flex alignItems="center" w="23%">
                                        <Flex mb="12px">-</Flex>
                                        <DynamicInput
                                          value={amount - totalSplitAmount}
                                          isReadOnly="true"
                                          border="none"
                                          background="transparent !important"
                                          type="number"
                                        />
                                      </Flex>
                                    )}
                                  </Flex>
                                  {inputs.map((input, index) => (
                                    <Flex
                                      key={index}
                                      w="100%"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      mb={4}
                                    >
                                      <Flex flexDir="column" w="65%">
                                        <DynamicInput
                                          value={input.name}
                                          onChange={(e) =>
                                            handleInputChange(e, index, "name")
                                          }
                                          add={true}
                                        />
                                        {!input.name && (
                                          <span className="helper-text">
                                            Name is required
                                          </span>
                                        )}
                                      </Flex>
                                      -
                                      <Flex flexDir="column" w="25%">
                                        <DynamicInput
                                          type="number"
                                          min="1"
                                          value={input.amount}
                                          onChange={(e) =>
                                            handleInputChange(
                                              e,
                                              index,
                                              "amount"
                                            )
                                          }
                                          add={true}
                                        />
                                        {!input.amount && (
                                          <span className="helper-text">
                                            Amount
                                          </span>
                                        )}
                                      </Flex>
                                      <Text
                                        p={0}
                                        pb={2}
                                        onClick={() => {
                                          removeInput(index);
                                        }}
                                      >
                                        <Icon as={CloseIcon} fontSize="md" />
                                      </Text>
                                    </Flex>
                                  ))}

                                  {detailTo !== "options" &&
                                    amount > 1 &&
                                    !trigger &&
                                    (name || isChecked) && (
                                      <Flex
                                        alignItems="center"
                                        justifyContent="center"
                                        w="100%"
                                      >
                                        <Button
                                          border="1px solid black"
                                          borderColor={colorMain}
                                          alignItems="center"
                                          justifyContent="center"
                                          gap={2}
                                          fontSize="xl"
                                          fontWeight="bold"
                                          borderRadius="10px"
                                          w="fit-content"
                                          background="transparent"
                                          onClick={addInput}
                                          isDisabled={isAddButtonDisabled}
                                        >
                                          <Icon as={HiPlusCircle} />
                                          {getActionWord(templateType)} for
                                          someone else
                                        </Button>
                                      </Flex>
                                    )}
                                </Flex>
                              </FadeIn>
                              {!data?.data.saledata?.sale_settings
                                ?.recurring_contributions ? (
                                <Checkbox
                                  mt={3}
                                  mb={8}
                                  size="xl"
                                  isChecked={isChecked}
                                  onChange={() => setIsChecked(!isChecked)}
                                >
                                  {getActionWord(templateType)} anonymously
                                </Checkbox>
                              ) : (
                                <Checkbox
                                  mb={8}
                                  size="xl"
                                  isChecked={recurring}
                                  onChange={() => setRecurring(!recurring)}
                                >
                                  Make this a{" "}
                                  {data?.data.saledata?.sale_settings
                                    ?.payment_cadence || "Recurring"}{" "}
                                  Payment
                                </Checkbox>
                              )}
                            </FadeIn>
                          )}

                        {data?.data.saledata?.sale_settings
                          ?.pay_detail_button ? (
                          detailTo && detailTo === "select" ? (
                            <>
                              <FormLabel fontSize="xl" mt={2} fontWeight="bold">
                                &nbsp;{" "}
                                {data?.data.saledata?.sale_settings
                                  ?.pay_detail_button_name || "Select"}
                              </FormLabel>

                              <DynamicSelectInput
                                mb={8}
                                options={urlOptions}
                                condition={selectedOption}
                                value={selection}
                                onChange={(e) => setSelection(e.target.value)}
                                required
                              />
                            </>
                          ) : detailTo && detailTo === "text" ? (
                            <>
                              <FormLabel fontSize="xl" mt={2} fontWeight="bold">
                                &nbsp;{" "}
                                {data?.data.saledata?.sale_settings
                                  ?.pay_detail_button_name || "Description"}
                              </FormLabel>

                              <Textarea
                                border="1px solid"
                                borderColor={colorMain}
                                type="text"
                                placeholder="Enter payment details here"
                                value={selection}
                                onChange={(e) => setSelection(e.target.value)}
                                required
                              />
                            </>
                          ) : detailTo && detailTo === "options" ? (
                            urlOptions?.length && (
                              <>
                                <FormLabel
                                  fontSize="xl"
                                  mt={2}
                                  ml={-2}
                                  fontWeight="bold"
                                >
                                  &nbsp;{" "}
                                  {data?.data.saledata?.sale_settings
                                    ?.pay_detail_button_name || "Select"}
                                </FormLabel>
                                <Text fontSize="xl" ml={2} fontFamily="poppins">
                                  Select one or multiple categories to proceed
                                </Text>

                                <OptionsWithCheckboxes
                                  urlOptions={urlOptions}
                                  colorMain={colorMain}
                                  setSelectionDetails={setSelectionDetails}
                                  setIsSelectButtonDisabled={
                                    setIsSelectButtonDisabled
                                  }
                                  setTotalSplitAmountB={setTotalSplitAmountB}
                                  amount={amount}
                                  setAmount={setAmount}
                                  toast={toast}
                                  setAnyChecked={setAnyChecked}
                                  isCheckedAanyChecked={isCheckedAanyChecked}
                                  totalSplitAmountB={totalSplitAmountB}
                                />
                              </>
                            )
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </FormControl>
                    </FadeIn>
                    {/* )} */}
                  </DrawerBody>

                  <DrawerFooter
                    align="center"
                    justify="center"
                    display="flex"
                    flexDir="column"
                  >
                    {!amount || amount < 1 ? (
                      <Tooltip
                        label="Please enter the amount to pay"
                        fontSize="xl"
                      >
                        <Button
                          onClick={() => setOnPayClick(true)}
                          bgColor={colorMain}
                          color="white"
                          align="center"
                          p="18px"
                          borderRadius="3px"
                          fontWeight="bold"
                          mb={6}
                          w="100%"
                          px={16}
                          fontSize="2xl"
                        >
                          Pay
                        </Button>
                      </Tooltip>
                    ) : (
                      <PayButton
                        colorMain={colorMain}
                        number={number}
                        phoneLength={phoneLength}
                        amount={amount}
                        name={isChecked ? "Anonymous" : name}
                        buttone={buttone}
                        selection={detailTo === "options" ? true : selection}
                        buttonDisable={buttonDisable}
                        isAddButtonDisabled={isAddButtonDisabled}
                        isSelectButtonDisabled={isSelectButtonDisabled}
                        trigger={trigger}
                        handlePayment={handlePayment}
                        nameOption={nameOption}
                      />
                    )}
                  </DrawerFooter>
                </DrawerContent>
              </>
            )}{" "}
          </>
        ) : (
          <>
            {!subActive ? (
              <DrawerContent mb="300">
                <DrawerCloseButton />
                <DrawerBody p={9} fontSize="2xl">
                  <Text>
                    Plan for this collection is not active. The Plan has expired
                    or number of contibutors has been exceeded. Contact the
                    organizer to renew or upgrade the plan. We apologize for the
                    inconvenience.
                  </Text>
                </DrawerBody>
              </DrawerContent>
            ) : (
              <DrawerContent borderTopRadius="20px">
                <DrawerCloseButton />
                <DrawerHeader mt={3} textAlign="center" color={colorMain}>
                  Pledge to contribute
                </DrawerHeader>
                <DrawerBody pb={6}>
                  <FormControl>
                    <Flex
                      // shadow="base"
                      borderRadius="10px"
                      flexDir="column"
                      px={3}
                      pt={4}
                      height="fit-content"
                    >
                      <Text fontWeight="bold" fontSize="1.8rem" pb={0} mb={0}>
                        Enter Amount
                      </Text>
                      <Flex
                        pb={amountBlank ? 0 : 2}
                        alignItems="baseline"
                        ml={
                          !pledgeAmount || pledgeAmount?.length < 1
                            ? "12"
                            : pledgeAmount?.length > 4
                            ? pledgeAmount?.length - 2
                            : "16"
                        }
                        justifyContent="center"
                        fontWeight="semibold"
                      >
                        <span
                          style={{
                            color: "#878787",
                            fontWeight: "lighter",
                            fontSize: "18px",
                            marginRight: "3px",
                          }}
                        >
                          KES
                        </span>
                        <Input
                          ref={initialRef}
                          type="number"
                          variant="unstyled"
                          fontSize={["7xl", "7xl"]}
                          placeholder={!pledgeAmount ? "0.00" : ""}
                          value={pledgeAmount}
                          onChange={(e) => setPledgeAmount(e.target.value)}
                          required
                          color={colorMain}
                          fontWeight="semibold"
                          // borderBottom="1px solid black"
                        />
                      </Flex>
                      {amountBlank && (
                        <Flex
                          p={0}
                          pb={2}
                          m={0}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <FormHelperText p={0} m={0} color="red" fontSize="md">
                            Amount must be greater than 0.
                          </FormHelperText>
                        </Flex>
                      )}
                    </Flex>
                  </FormControl>
                  <FormControl mt={8} isRequired fontSize="2xl">
                    <Heading fontSize="2xl" fontWeight="semibold" my={4}>
                      Make a pledge to contribute
                    </Heading>

                    <FormLabel fontSize="2xl" fontWeight="bold">
                      &nbsp; Phone number
                    </FormLabel>
                    <DynamicInput
                      mb={8}
                      type="tel"
                      placeholder="Enter number here"
                      value={pledgeNumber}
                      onChange={(e) =>
                        handleInputPledge(e, setPhoneLength, setPledgeNumber)
                      }
                      required
                    />

                    {pledgeNumber?.length === phoneLength && (
                      <FadeIn>
                        <FormLabel fontSize="2xl" fontWeight="bold">
                          &nbsp; Name
                        </FormLabel>
                        <DynamicInput
                          mb={8}
                          type="text"
                          placeholder={!customerName ? "Enter name here" : ""}
                          value={customerName}
                          onChange={(e) => setCustomerName(e.target.value)}
                          required
                          isDisabled={disabled}
                        />
                      </FadeIn>
                    )}

                    {buttone ? (
                      detailTo && detailTo === "select" ? (
                        <>
                          <FormLabel fontSize="2xl" mt={2} fontWeight="bold">
                            &nbsp;{" "}
                            {data?.data.saledata?.sale_settings
                              ?.pay_detail_button_name || "Select"}
                          </FormLabel>
                          <DynamicSelectInput
                            mb={8}
                            options={urlOptions}
                            condition={selectedOption}
                            value={selection}
                            onChange={(e) => setSelection(e.target.value)}
                            required
                          />
                        </>
                      ) : detailTo && detailTo === "text" ? (
                        <>
                          <FormLabel fontSize="xl" mt={2} fontWeight="bold">
                            &nbsp;{" "}
                            {data?.data.saledata?.sale_settings
                              ?.pay_detail_button_name || "Description"}
                          </FormLabel>

                          <DynamicInput
                            mb={8}
                            type="text"
                            placeholder="Enter payment details here"
                            value={selection}
                            onChange={(e) => setSelection(e.target.value)}
                            required
                          />
                        </>
                      ) : detailTo && detailTo === "options" ? (
                        urlOptions?.length && (
                          <>
                            <FormLabel fontSize="2xl" mt={2} fontWeight="bold">
                              &nbsp;{" "}
                              {data?.data.saledata?.sale_settings
                                ?.pay_detail_button_name || "Select"}
                            </FormLabel>
                            <DynamicSelectInput
                              mb={8}
                              options={urlOptions}
                              condition={selectedOption}
                              value={selection}
                              onChange={(e) => setSelection(e.target.value)}
                              required
                            />
                          </>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </FormControl>
                  <Flex
                    align="center"
                    justify="center"
                    display="flex"
                    flexDir="column"
                  >
                    <ActionButton
                      onClick={handlePledgeContribution}
                      text={`Pledge Amount`}
                      isLoading={trigger}
                      isDisabled={
                        pledgeNumber?.length === phoneLength &&
                        pledgeAmount > 0 &&
                        customerName &&
                        buttonDisable(buttone, selection)
                          ? false
                          : true
                      }
                      colorMain={colorMain}
                      spinnerColor="teal"
                    />
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            )}
          </>
        )}
      </Drawer>
    );
  }
);

export default DynamicDrawer;
