// Error page
import React from "react";

const LoadingPage = () => {
  return (
    <div className="center">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h6>
        <small>Loading..</small>
      </h6>
    </div>
  );
};

export default LoadingPage;
