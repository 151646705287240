import React, { useState } from "react";
import { colorMain } from "../../constants/themes";

const Truncate = ({ text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const words = text?.split(" ");

  if (words?.length <= maxLength && isTruncated) {
    return <p>{text}</p>;
  }
  const truncatedText = isTruncated
    ? words?.slice(0, maxLength).join(" ") + "..."
    : text;

  return (
    <div>
      {truncatedText}
      {words?.length > maxLength && (
        <button
          onClick={toggleTruncate}
          style={{
            color: colorMain,
            fontWeight: "bold",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          {isTruncated ? "Read More" : "...Read Less"}
        </button>
      )}
    </div>
  );
};

export default Truncate;
