import qr from "qrcode";

const generateQRCode = async (data) => {
  try {
    const qrDataURL = await qr.toDataURL(data);
    return qrDataURL;
  } catch (error) {
    console.error("Error generating QR code:", error);
    return null;
  }
};

export default generateQRCode;
