import { Text, Flex, Image, VStack, HStack } from "@chakra-ui/react";
import React from "react";
import { ToastContainer } from "react-toastify";
import FadeIn from "react-fade-in/lib/FadeIn";

const Advanced = (props) => {
  const {
    type,
    dataSale,
    payPart,
    original_date,
    docTermsService,
    bg,
    docImageUrl,
    paid,
    logo,
    docHexCode,
    paymentData,
    handlePayment,
    handleGeneratePdfDocument,
  } = props;
  const colorCode = docHexCode || "black";
  const calcTotal =
    dataSale?.sale_amount -
    dataSale?.sale_balance +
    Math.trunc(
      dataSale?.sale_tax >= 1
        ? (dataSale?.sale_tax / 100) * dataSale?.sale_amount
        : dataSale?.sale_tax * dataSale?.sale_amount
    ) +
    Math.trunc(
      dataSale?.sale_discount >= 1
        ? (dataSale?.sale_discount / 100) * dataSale?.sale_amount
        : dataSale?.sale_discount * dataSale?.sale_amount
    );

  return (
    <FadeIn>
      <Flex
        width="100%"
        minH="100vh"
        boxSizing="border-box"
        flexDir="column"
        alignItems="center"
        justifyContent={["normal", "center"]}
        bgImage={bg}
      >
        <Flex
          paddingTop={["2rem", ""]}
          flexDir="column"
          minH={["90vh", "100vh"]}
          w="100%"
          bg="white"
          fontSize={["1.4rem", "2xl"]}
          position="relative"
          shadow="base"
          maxW={["100%", "568px"]}
          justifyContent="space-between"
          alignItems="center"
        >
          <ToastContainer zIndex="1001" />
          <Flex
            flex="2"
            pt={["6px", "15px"]}
            px={["6px", "35px"]}
            flexDir="column"
            w="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              pl={["4", "revert"]}
            >
              <HStack alignItems="baseline">
                <Image
                  src={docImageUrl}
                  width={["13%", "13%"]}
                  padding={0}
                  alt=""
                />
              </HStack>
              {dataSale.sale_amount - calcTotal === 0 && (
                <Image src={paid} width={["30%", "15%"]} />
              )}
            </Flex>

            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
            >
              <Flex
                bg={colorCode}
                borderRightRadius="25px"
                color="white"
                p={["1", "3"]}
                position="absolute"
                left={["-6px", "-35px"]}
              >
                <Text fontWeight="bold" pl={["1", "4"]}>
                  &nbsp;
                </Text>
              </Flex>
              <Flex flexDir="column" pl={["4", "revert"]}>
                <Text textTransform="uppercase" fontWeight="bold">
                  {dataSale.org_data.name}
                </Text>
                <Text>{dataSale.org_data.location}</Text>
                <Text>{dataSale.org_data.main_phone_number}</Text>
              </Flex>
              <Flex
                bg={colorCode}
                borderLeftRadius="25px"
                color="white"
                p={3}
                position="absolute"
                right={["-6px", "-35px"]}
              >
                <Text fontWeight="bold" pl={12}>
                  {type
                    ? type === "Sale"
                      ? "BILL"
                      : type === "Quote"
                      ? "QUOTE"
                      : type === "Invoice"
                      ? "INVOICE"
                      : type === "Collection"
                      ? "COLLECT"
                      : "RECEIPT"
                    : "RECEIPT"}
                </Text>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              mt={["1", "3"]}
              justifyContent="space-between"
              paddingTop={["2rem", "revert"]}
              w="100%"
            >
              <Flex flexDir="column">
                <Text fontWeight="bold" color={colorCode}>
                  Bill to:
                </Text>
                <Text> {dataSale.contact_name}</Text>
              </Flex>
              <Flex flexDirection="column" textAlign="right" alignItems="end">
                <HStack>
                  <Text fontWeight="bold" color={colorCode}>
                    {type
                      ? type === "Sale"
                        ? "Bill #:"
                        : type === "Quote"
                        ? "Quote #:"
                        : type === "Invoice"
                        ? "Invoice #:"
                        : "Receipt #:"
                      : "Receipt #:"}
                  </Text>
                  <Text>{dataSale.sale_trx_code}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold" color={colorCode}>
                    Date:
                  </Text>
                  <Text>{original_date}</Text>
                </HStack>
              </Flex>
            </Flex>
            <Flex flexDirection="column" width="100%" mt={["1", "3"]}>
              <table className="table">
                <thead>
                  <tr
                    style={{
                      color: `${colorCode}`,
                      borderBottom: `3px solid ${colorCode}`,
                    }}
                  >
                    <th className="align">Description</th>
                    <th style={{ textAlign: "center" }}>Amount</th>
                    <th style={{ textAlign: "center" }}>Quantity</th>
                    <th style={{ textAlign: "right" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {dataSale.items_data.map((item) => {
                    return (
                      <tr key={item.uniqueId} style={{ fontWeight: "normal" }}>
                        <td className="align" style={{ fontWeight: "normal" }}>
                          {item.item_name}
                        </td>
                        <td
                          style={{ textAlign: "center", fontWeight: "normal" }}
                        >
                          {item.item_amount}
                        </td>
                        <td
                          style={{ textAlign: "center", fontWeight: "normal" }}
                        >
                          {item.item_quantity}
                        </td>
                        <td
                          className="text-right"
                          style={{ fontWeight: "normal" }}
                        >
                          {item.item_quantity * item.item_amount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Flex justifyContent="right" pr={["4"]}>
                <VStack fontWeight="bold">
                  {dataSale?.sale_amount ===
                  dataSale?.sale_balance - calcTotal ? (
                    <HStack
                      width="100%"
                      alignItems="center"
                      fontSize={["1.7rem", "1.6rem"]}
                      justifyContent="space-between"
                      color={colorCode}
                      borderTop={[
                        `1px solid ${colorCode}`,
                        `2px solid ${colorCode}`,
                      ]}
                      py={2}
                      borderBottom={[
                        `1px solid ${colorCode}`,
                        `2px solid ${colorCode}`,
                      ]}
                    >
                      <Text>Total:</Text>
                      <Text>
                        <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                        {dataSale?.sale_amount}
                      </Text>
                    </HStack>
                  ) : (
                    <>
                      <HStack
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                        color={colorCode}
                      >
                        <Text>Total:</Text>
                        <Text>
                          <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                          {dataSale?.sale_amount}
                        </Text>
                      </HStack>
                      {dataSale?.sale_amount !== dataSale?.sale_balance && (
                        <HStack
                          width="100%"
                          alignItems="center"
                          mt={1}
                          justifyContent="space-between"
                          color={colorCode}
                        >
                          <Text>Paid:</Text>
                          <Text>
                            <span style={{ fontSize: "1.1rem" }}>KES</span>
                            &nbsp;
                            {dataSale?.sale_amount - dataSale?.sale_balance}
                          </Text>
                        </HStack>
                      )}
                      {dataSale?.sale_discount > 0 && (
                        <HStack
                          width="100%"
                          alignItems="center"
                          mt={1}
                          justifyContent="space-between"
                          color={colorCode}
                        >
                          <Text>Discount:</Text>
                          <Text>
                            <span style={{ fontSize: "1.1rem" }}>KES</span>
                            &nbsp;{" "}
                            {dataSale?.sale_discount >= 1
                              ? Math.trunc(
                                  (dataSale?.sale_discount / 100) *
                                    dataSale?.sale_amount
                                )
                              : Math.trunc(
                                  dataSale?.sale_discount *
                                    dataSale?.sale_amount
                                )}
                          </Text>
                        </HStack>
                      )}
                      {dataSale?.sale_tax > 0 && (
                        <HStack
                          width="100%"
                          alignItems="center"
                          mt={1}
                          justifyContent="space-between"
                          color={colorCode}
                        >
                          <Text>Tax:</Text>
                          <Text>
                            <span style={{ fontSize: "1.1rem" }}>KES</span>
                            &nbsp;
                            {dataSale?.sale_tax >= 1
                              ? Math.trunc(
                                  (dataSale?.sale_tax / 100) *
                                    dataSale?.sale_amount
                                )
                              : Math.trunc(
                                  dataSale?.sale_tax * dataSale?.sale_amount
                                )}
                          </Text>
                        </HStack>
                      )}
                      <HStack
                        width="100%"
                        alignItems="center"
                        mt={1}
                        justifyContent="space-between"
                        py={2}
                        borderTop="1px solid black"
                        borderBottom="1px solid black"
                      >
                        <Text>Sub total</Text>
                        <Text>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
                        <Text>
                          <span style={{ fontSize: "1.1rem" }}>KES</span>&nbsp;
                          {dataSale.sale_amount - calcTotal}
                        </Text>
                      </HStack>
                    </>
                  )}
                </VStack>
              </Flex>
            </Flex>
            <Flex
              width="100%"
              justifyContent="space-between"
              mt={["2rem", "2rem"]}
              pr={["4"]}
              fontSize={["1.2rem", "1.4rem"]}
            >
              {/* <Flex flexDir='column'>
                                 {
                                    paymentData &&
                                    <>
                                        <Text fontWeight='bold' color={colorCode}>Payment method</Text>
                                        <Flex>
                                            <UnorderedList>
                                                <ListItem fontWeight='bold'>{paymentData.pay_type_main}</ListItem>
                                                {
                                                    paymentData.pay_detail_2 ?
                                                        <>
                                                            <ListItem listStyleType='none'>Paybill number: {paymentData.pay_detail_1}</ListItem>
                                                            <ListItem listStyleType='none'>Account number: {paymentData.pay_detail_2}</ListItem>
                                                        </>
                                                        :
                                                        <ListItem listStyleType='none'>Till number: {paymentData.pay_detail_1}</ListItem>
                                                }
                                            </UnorderedList>
                                        </Flex>
                                    </>
                                }
                            </Flex>
                            <Flex flexDir='column' textAlign='right'>
                                <Text fontWeight='bold'>{dataSale.org_data.name}</Text>
                                <Text>{dataSale.org_data.location}</Text>
                                <Text>{dataSale.org_data.main_phone_number}</Text>
                            </Flex> */}
            </Flex>
            <Flex paddingTop="1rem">
              {payPart &&
                React.cloneElement(payPart, {
                  handlePayment,
                  handleGeneratePdfDocument,
                  calcTotal,
                })}
            </Flex>
          </Flex>
          <Flex
            p={2}
            bgColor={colorCode}
            color="white"
            w="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text w="60%" fontSize={["1rem", "xl"]} fontWeight="semibold">
              {docTermsService || "Terms and conditions apply"}
            </Text>
            <Flex w="30%" flexDir="column" alignItems="end">
              <Text fontSize="1rem" p={0} pr={12}>
                Powered by
              </Text>
              <Image src={logo} p={0} width={["60%", "35%"]} alt="Nobuk" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </FadeIn>
  );
};

export default Advanced;
