import React, { useEffect, useRef, useState } from "react";
import { generateReceipt } from "./Content/pdfMaker";
import { Button, Flex } from "@chakra-ui/react";
import { colorMain } from "../constants/themes";
import { handleGetReceiptDetails } from "../utils/api";
import { createFileName, useScreenshot } from "use-react-screenshot";
import { useParams } from "react-router";
import LoadingPage from "./LoadingPage";
import FourOFour from "./404";
import { ToastContainer, toast } from "react-toastify";

import { Text, Circle, Spinner, Card, Icon } from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import PaymentConfirmation from "../components/Features/PaymentConfirmation";
import {
  formatterPaidBy,
  formatterTimePaid,
  titleCase,
} from "../utils/functions";
import { AiFillCheckCircle } from "react-icons/ai";

const ReceiptDownload = () => {
  const { mpesaCode } = useParams();
  // console.log(mpesaCode);
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState(false);
  const [load, setLoad] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [time, setTime] = useState(100);
  setTimeout(() => {
    setTime(0);
  }, 60000);
  const ref = useRef(null);
  const refs = useRef(null);

  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const [downloading, setIsDownloading] = useState(false);
  const [receipt, setReceipt] = useState(true);

  useEffect(() => {
    mpesaCode && handleGetReceiptDetails(mpesaCode, setData, toast, setClicked, setLoad);
    
  }, []);

  const getImage = async () => {
    if (receipt) {
      setReceipt(false);
      return;
    }

    setIsDownloading(true);

    try {
      const dataUri = await takeScreenshot(refs.current);
      downloadImage(dataUri);
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }

    setIsDownloading(false);
    setReceipt(true);
  };

  useEffect(() => {
    if (!receipt) {
      getImage();
    }
  }, [receipt]);

  const getThisImage = async () => {
    setIsDownloading(true);
    try {
      await takeScreenshot(refs.current);
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
    setIsDownloading(false);
  };

  const downloadImage = (dataUri) => {
    setIsDownloading(true);
    const link = document.createElement("a");
    link.href = dataUri;
    link.download = "receipt.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  return (
    <>
      {data && (
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mb={2}
          mt={2}
          width={["100vw", "", ""]}
          height={["100vh"]}
        >
          <ToastContainer />
          {clicked ? (
            <Flex
              h="300px"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="white"
                size="xl"
              />
              <Text fontSize="md">Checking payment...</Text>
            </Flex>
          ) : (
            <Flex
              flexDir="column"
              p={3}
              ref={refs}
              minWidth="370px"
              maxW="100vw !important"
            >
              <Card
                bg="white"
                shadow="2xl"
                color={colorMain}
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                borderTopRadius="2xl"
                borderBottomRadius="none"
                pt={3}
                mt={12}
                position="relative"
                borderWidth=".001rem"
                borderBottom="none"
                borderColor="#cccccc"
              >
                <Circle bg="white" size="24" top="-35px" position="absolute">
                  <AiFillCheckCircle fontSize="50px" color="#40D195" />
                </Circle>
                <Text
                  fontWeight="bold"
                  textTransform="uppercase"
                  fontSize="3xl"
                  pt={1}
                  mt="20px"
                  pb={0}
                  mb={0}
                >
                  {"Payment Found!"}
                </Text>
                <Text
                  fontWeight="medium"
                  p={1}
                  color="gray"
                  fontSize="1.2rem"
                  pb={4}
                  textAlign="center"
                >
                  Thank you, your payment was received
                </Text>
                <hr
                  style={{
                    padding: "0%",
                    margin: "0%",
                    width: "90%",
                    height: ".083rem",
                    color: "#062a30af",
                    opacity: "0.3",
                  }}
                />
              </Card>

              <Card
                bg="white"
                color={colorMain}
                pb={6}
                borderTopRadius="none"
                borderBottomRadius="2xl"
                mb={8}
                // outline={`.2rem solid ${colorMain}`}
                borderWidth=".001rem"
                borderTop="none"
                borderColor="#cccccc"
              >
                <Flex
                  flexDir="column"
                  alignItems="center"
                  p={6}
                  py={0}
                  ref={ref}
                  background="transparent"
                >
                  <Flex
                    display="flex"
                    alignItems="baseline"
                    justifyContent="space-between"
                    pt={2}
                  >
                    <Text fontWeight="bold" color="gray">
                      KES
                    </Text>
                    <Text fontWeight="bold" ml={2} fontSize="6xl">
                      {data?.amount !== undefined
                        ? Number(data?.amount).toFixed(2)
                        : "1.00"}
                    </Text>
                  </Flex>
                  <hr
                    style={{
                      padding: "0%",
                      margin: "0%",
                      width: "90%",
                      height: ".033rem",
                      color: "#062a30af",
                      opacity: "0.025",
                    }}
                  />
                  <PaymentConfirmation
                    title="Paid by:"
                    content={titleCase(
                      formatterPaidBy(data?.paid_by) || "Anonymous"
                    )}
                  />
                  <PaymentConfirmation
                    title="From"
                    content={data?.phone || "2547********"}
                  />

                  {data?.time_paid && (
                    <PaymentConfirmation
                      title="Date"
                      content={formatterTimePaid(data?.time_paid)}
                    />
                  )}
                  {data?.transcode && (
                    <PaymentConfirmation
                      title={" Mpesa code"}
                      content={data?.transcode}
                    />
                  )}
                  <Flex
                    w="100%"
                    alignItems="center"
                    mb={6}
                    justifyContent="center"
                  >
                    <hr
                      style={{
                        padding: "0%",
                        margin: "0%",
                        width: "95%",
                        height: ".083rem",
                        color: "#062a30af",
                        opacity: "0.31",
                      }}
                    />
                  </Flex>
                </Flex>

                <Flex alignItems="center" justifyContent="center">
                  <Button
                    bgColor={colorMain}
                    shadow="sm"
                    px={12}
                    py={7}
                    color={receipt ? "white" : `${colorMain}`}
                    borderRadius="md"
                    fontSize={["xl", "2xl"]}
                    fontWeight="bold"
                    isDisabled={processing || downloading}
                    isLoading={processing || downloading}
                    loadingText="processing.."
                    onClick={() => {
                      if (data && data.transcode) {
                        generateReceipt({
                          phoneNumberBy: data.phone || "2547********",
                          paidBy: data.paid_by || "Unknown",
                          amount: data?.amount,
                          transactionCode: data?.transcode || mpesaCode,
                          orgName: data?.collection_name || "Unknown",
                          selection: data?.["Paid For"] || "Unknown",
                          setProcessing,
                          time_paid: data?.time_paid,
                        });
                      } else {
                        toast.update("Please refresh and try again!");
                      }
                    }}
                  >
                    <Flex alignItems="center" justifyContent="center" gap={2}>
                      Download Receipt
                    </Flex>
                  </Button>
                </Flex>
              </Card>
            </Flex>
          )}
        </Flex>
      )}

      {(load || time === 60000) && <LoadingPage />}

      {!load && !data && time === 0 && <FourOFour />}
      {!load && !data && time !== 0 && (
        <Flex
          flexDir="column"
          p={3}
          ref={refs}
          alignItems="center"
          justifyContent="center"
          minWidth="370px"
          height="100vh"
          maxW="100vw !important"
        >
          <ToastContainer />

          <Card
            bg="white"
            minWidth="370px"
            boxShadow="none"
            shadow="2xl"
            color={colorMain}
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            borderTopRadius="2xl"
            borderBottomRadius="none"
            pt={3}
            mt={12}
            position="relative"
            borderWidth=".001rem"
            borderColor="red"
          >
            <Circle bg="white" size="24" top="-35px" position="absolute">
              <WarningIcon fontSize="50px" color="red" />
            </Circle>
            <Text
              fontWeight="bold"
              textTransform="uppercase"
              fontSize="3xl"
              pt={1}
              mt="20px"
              pb={0}
              mb={0}
            >
              {"Payment Not Found!"}
            </Text>
            <Text
              fontWeight="medium"
              p={1}
              color="gray"
              fontSize="1.4rem"
              pb={4}
              textAlign="center"
            >
              Sorry! The payment code provided is invalid.
            </Text>
            <hr
              style={{
                padding: "0%",
                margin: "0%",
                width: "90%",
                height: ".083rem",
                color: "#062a30af",
                opacity: "0.3",
              }}
            />
          </Card>
        </Flex>
      )}
    </>
  );
};
export default ReceiptDownload;
