import { Button } from "@chakra-ui/react";
import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

const DynamicButton = ({
  onClickHandler,
  buttonText,
  isLoading,
  colorMain,
  isDisabled,
}) => {
  return (
    <Button
      onClick={onClickHandler}
      type="submit"
      position="relative"
      borderColor="teal.200"
      w="100%"
      bg={colorMain}
      color="white"
      align="center"
      fontSize={["14px", "14px", "17px"]}
      p="19px"
      mb={8}
      isDisabled={isDisabled || false}
      py={7}
      borderRadius="8px"
      shadow="base"
      fontWeight="bold"
      isLoading={isLoading}
      colorScheme="blue"
      spinner={<BeatLoader size={8} color="teal" />}
      _hover={{
        bg: "white",
        color: "#2fb5b0",
      }}
      _active={{
        bg: "white",
        color: "#2fb5b0",
      }}
    >
      {buttonText}
    </Button>
  );
};

export const PayButton = ({
  colorMain,
  number,
  phoneLength,
  amount,
  name,
  buttone,
  selection,
  trigger,
  handlePayment,
  buttonDisable,
  isAddButtonDisabled,
  isSelectButtonDisabled
}) => {
  const isButtonDisabled =
    number?.length === phoneLength && name && buttonDisable(buttone, selection)
      ? false
      : true;

  return (
    <Button
      onClick={handlePayment}
      bgColor={colorMain}
      type="submit"
      color="white"
      align="center"
      p="18px"
      letterSpacing="wider"
      borderRadius="8px"
      boxShadow="base"
      fontWeight="bold"
      isDisabled={isButtonDisabled || isAddButtonDisabled || isSelectButtonDisabled}
      mb={6}
      w="100%"
      isLoading={trigger}
      px={16}
      fontSize="2xl"
      spinner={<BeatLoader size={8} color="teal" />}
      _hover={{
        bg: "white",
        color: "#062a30",
        border: "2px solid teal",
      }}
      _active={{
        bg: "white",
        color: "#062a30",
        border: "2px solid teal",
      }}
    >
      Pay Now
    </Button>
  );
};

export const ActionButton = ({
  onClick,
  text,
  isLoading,
  isDisabled,
  colorMain,
  spinnerColor,
}) => {
  return (
    <Button
      bgColor={colorMain}
      onClick={onClick}
      type="submit"
      color="white"
      align="center"
      p="18px"
      letterSpacing="wider"
      w="100%"
      borderRadius="8px"
      px={16}
      fontSize="2xl"
      boxShadow="base"
      fontWeight="bold"
      isDisabled={isDisabled}
      mb={6}
      isLoading={isLoading}
      spinner={<BeatLoader size={8} color={spinnerColor} />}
      _hover={{
        bg: "white",
        color: "#062a30",
        border: "2px solid teal",
      }}
      _active={{
        bg: "white",
        color: "#062a30",
        border: "2px solid teal",
      }}
    >
      {text}
    </Button>
  );
};

export const OptionsButton = ({
  colorMain,
  buttonText,
  isLoading,
  onClick,
  isDisabled,
}) => {
  return (
    <Button
      type="submit"
      loadingText="updating"
      h="38"
      w="100%"
      mb="5px"
      fontSize={["md", "xl"]}
      borderRadius="8px"
      bg={colorMain}
      color="white"
      mt="4px"
      fontWeight="bold"
      isLoading={isLoading}
      spinner={<BeatLoader size={8} color="white" />}
      variant="outline"
      _hover={{
        color: colorMain,
        bg: "white",
      }}
      _active={{
        bg: "teal.400",
      }}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {buttonText}
    </Button>
  );
};

export default DynamicButton;
