import React from "react";
import { Input } from "@chakra-ui/react";

const DynamicInput = ({
  type,
  placeholder,
  reff,
  value,
  onChange,
  add,
  ...restProps
}) => {
  return (
    <Input
      ref={reff}
      borderRadius="3px"
      backgroundColor="white"
      border="1px solid #eeeeee"
      borderColor="black"
      mb={add && add ? "0px" : "12px"}
      type={type}
      placeholder={placeholder}
      fontSize="2xl"
      size="lg"
      autoComplete="off"
      onChange={onChange}
      value={value}
      required
      _hover={{
        outline: "none",
      }}
      _active={{
        bg: "white",
        color: "#2fb5b0",
        outline: "none",
      }}
      {...restProps}
    />
  );
};

export default DynamicInput;
