// Themes
import { extendTheme } from "@chakra-ui/react";
import { checkboxTheme } from './checkbox'

export const colorAlt = "#2FB5B0";
export const colorMain = "#062a30";
const theme = extendTheme({
  components: {
    Drawer: {
      parts: ["dialog", "header", "body"],
      variants: {
        primary: {},
        secondary: {
          dialog: {
            maxW: "390px",
            w: '390px',
          },
        },
      },
    },
    Checkbox: checkboxTheme 
  },
  calendarIcon: {
    textColor: "white",
    primaryColor: "#062a30", 
    backgroundColor: "#fafafa",
  },
});

export default theme;

export const CollectButtonStyles = {
  position: "relative",
  borderColor: "teal.200",
  bg: colorMain,
  color: "white",
  align: "center",
  fontSize: ["14px", "14px", "17px"],
  p: "19px",
  mb: 8,
  borderRadius: "12px",
  shadow: "base",
  fontWeight: "bold",
  colorScheme: "blue",
  w: "100%",
  _hover: {
    bg: "white",
    color: "#2fb5b0",
  },
  _active: {
    bg: "white",
    color: "#2fb5b0",
  },
};
