import React from 'react'
import logo from "../assets/img/fullBlack.webp";

const FourOFour = () => {
  return (
    <div id="oopss">
      <div id="error-text">
        <img
          src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
          alt="404"
        />
        <span>404 PAGE</span>
        <p className="p-a">. The page you were looking for could not be found</p>
        <p className="p-b">Kindly verify the link is correct</p>
      </div>

      <div className="power">
        <p style={{ color: "black" }}>Powered by</p>
        
          <img
            className="img-responsive"
            src={logo}
            alt="NOBUK"
          />
      </div>
    </div>
  )
}

export default FourOFour