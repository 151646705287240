import { Box, useRadio, Tooltip } from "@chakra-ui/react";
import { colorMain } from "../../constants/themes";

export function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" variant="unstyled" flex="1">
      <input {...input} />
      <Tooltip color='white' bg={colorMain} hasArrow label={`click to pay with ${props.value}`} fontSize={["1.2rem"]}>
        <Box
          {...checkbox}
          cursor="pointer"
          borderRadius="md"
          required
          px={4}
          color={colorMain}
          outline={`.0rem solid ${colorMain}`}
          fontWeight="bold"
          bgColor="white"
          h="36px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          variant="unstyled"
          border="none"
          _checked={{
            color: "white",
            bg: "#062a30",
            borderColor: "white",
            borderWidth: "2px",
          }}
        >
          {props.children}
        </Box>
      </Tooltip>
    </Box>
  );
}
