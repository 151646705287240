import { Image, Link } from "@chakra-ui/react";
import React from "react";

const Footer = ({ footerData }) => {
  return (
    <Link
      _hover={{
        bg: "white",
        color: "#2fb5b0",
      }}
      _active={{
        bg: "white",
        color: "#2fb5b0",
      }}
      href={`${footerData?.adlink}`}
      isExternal
    >
      <Image
        src={footerData?.adurl}
        objectFit="cover"
        aspectRatio="16/9"
        alt="collect with ease"
      />
    </Link>
  );
};

export default Footer;
