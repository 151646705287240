// Functions
import { collectUrl } from "../constants/variables";

export const extractData = (dataSale, data) => {
  const { sale_type, sales_date, org_id, sale_trx_code } = dataSale;
  const { saledata } = data?.data || {};
  const { paylink_id } = saledata || {};

  const timestamp = new Date(
    sales_date && sales_date?.toString()?.length < 13
      ? sales_date * 1000
      : sales_date
  );
  const original_date = formatDate(timestamp);

  return {
    type: sale_type,
    org_id,
    sales_code: sale_trx_code,
    paylink_id,
    original_date,
  };
};

export const formatDate = (date) => {
  const todate = date.getDate();
  const tomonth = date.getMonth() + 1;
  const toyear = date.getFullYear();
  return `${todate}/${tomonth}/${toyear}`;
};

export const handleCardClick = (name, number) => {
  const newPageUrl = `${collectUrl}/register?name=${encodeURIComponent(
    name
  )}&number=${encodeURIComponent(number?.replace(/^0/, "254"))}`;
  window.open(newPageUrl, "_blank");
};

export const handleCloseThisModal = (setIsToOpen) => {
  window.location.reload();
  setIsToOpen(false);
};

export const titleCase = (str) => {
  return str?.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const handleInput = (e, setPhoneLength, setNumber, cardOption) => {
  const inputValue = e?.target?.value;
  // Replace any non-numeric characters with an empty string
  const sanitizedValue = inputValue?.replace(/\D/g, "");
  let truncatedLength = 12;
  if (sanitizedValue.startsWith("254")) {
    truncatedLength = 12;
  } else if (sanitizedValue.startsWith("0")) {
    truncatedLength = 10;
  }
  setPhoneLength(truncatedLength);
  const truncatedValue = sanitizedValue.substring(0, truncatedLength);
  setNumber(truncatedValue);
  if (cardOption) {
    setPhoneLength(Math.max(4, inputValue?.length));
    setNumber(inputValue);
  }
};

export const handleInputSurvey = (e, setPhoneLength, setNumber) => {
  const inputValue = e?.target?.value;
  // Replace any non-numeric characters with an empty string
  const sanitizedValue = inputValue?.replace(/\D/g, "");
  let truncatedLength = 12;
  if (sanitizedValue.startsWith("254")) {
    truncatedLength = 12;
  } else if (sanitizedValue.startsWith("0")) {
    truncatedLength = 10;
  }
  setPhoneLength(truncatedLength);
  const truncatedValue = sanitizedValue.substring(0, truncatedLength);
  setNumber(truncatedValue);
};

export const handleInputPledge = (e, setPhoneLength, setPledgeNumber) => {
  const inputValue = e?.target?.value;
  // Replace any non-numeric characters with an empty string
  const sanitizedValue = inputValue?.replace(/\D/g, "");
  let truncatedLength = 12;
  if (sanitizedValue.startsWith("254")) {
    truncatedLength = 12;
  } else if (sanitizedValue.startsWith("0")) {
    truncatedLength = 10;
  }
  setPhoneLength(truncatedLength);
  const truncatedValue = sanitizedValue.substring(0, truncatedLength);
  setPledgeNumber(truncatedValue);
};

export const handleInputChange1 = (e, setName) => {
  setName(e.target.value);
};

export const handleInputChange2 = (e, setPhoneLength, setNumber) => {
  const inputValue = e.target.value;
  // Replace any non-numeric characters with an empty string
  const sanitizedValue = inputValue?.replace(/\D/g, "");
  let truncatedLength = 12;
  if (sanitizedValue.startsWith("254")) {
    truncatedLength = 12;
  } else if (sanitizedValue.startsWith("0")) {
    truncatedLength = 10;
  }
  setPhoneLength(truncatedLength);
  const truncatedValue = sanitizedValue.substring(0, truncatedLength);
  setNumber(truncatedValue);
};

export const handleInputChange3 = (e, setAmount) => {
  setAmount(e.target.value);
};

export const downloadPdf = (base64String, fileName) => {
  const binaryString = atob(base64String);
  const binaryData = new Uint8Array(
    [...binaryString].map((char) => char.charCodeAt(0))
  );
  const blob = new Blob([binaryData], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
};

export const timeDate = (t) => {
  const dateRegex =
    /^(?:(?:19|20)\d\d)-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

  if (dateRegex.test(t)) {
    return t;
  }

  let timestamp;

  if (t.toString().length === 16) {
    timestamp = t / 1000;
  } else if (t.toString().length === 19) {
    timestamp = t / 1000000;
  } else if (t.toString().length > 10) {
    timestamp = t;
  } else {
    timestamp = t * 1000;
  }

  let date = new Date(Number(timestamp));

  if (isNaN(date.getTime())) {
    return null;
  }

  let todate = date.getDate();
  let tomonth = date.getMonth() + 1;
  let toyear = date.getFullYear();

  let formattedDate = `${todate < 10 ? "0" : ""}${todate}/${
    tomonth < 10 ? "0" : ""
  }${tomonth}/${toyear}`;
  return formattedDate;
};

export const formatPayDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day < 10 ? "0" : ""}${day}/${
    month < 10 ? "0" : ""
  }${month}/${year}`;
};

export const renderMessageWithLinks = (message) => {
  // Check if the message contains HTML tags
  const containsHtmlTags = /<\/?[\w\s="/.':;#-/?]+>/gi.test(message);

  if (containsHtmlTags) {
    // Render HTML tags using dangerouslySetInnerHTML
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `<div style="line-height: 2.5rem; text-align: center; display: flex; justify-content: center; align-items: center;  padding-left: 2px; padding-right: 2px;">${message}</div>`,
        }}
      />
    );
  } else {
    // Regular expression to find URLs in the message
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the message based on the URLs found and wrap URLs with <a> tags
    return message.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "rgba(0, 0, 255, 0.6)",
              textDecoration: "underline",
            }}
          >
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  }
};

export const renderMessageWithLinksDesc = (message) => {
  // Check if the message contains HTML tags
  const containsHtmlTags = /<\/?[\w\s="/.':;#-/?]+>/gi.test(message);

  if (containsHtmlTags) {
    // Render HTML tags using dangerouslySetInnerHTML
    return (
      <div dangerouslySetInnerHTML={{ __html: `<div>${message}</div>` }} />
    );
  } else {
    // Regular expression to find URLs in the message
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the message based on the URLs found and wrap URLs with <a> tags
    return message.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "rgba(0, 0, 255, 0.6)",
              textDecoration: "underline",
            }}
          >
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  }
};

export const formatterTimePaid = (time_paid) => {
  if (time_paid) {
    console.log(time_paid);
    const timeString = time_paid.toString();
    time_paid = new Date(
      timeString.length === 16
        ? time_paid / 1000
        : timeString.length === 19
        ? time_paid / 1000000
        : timeString.length > 10
        ? time_paid
        : time_paid * 1000
    );
    time_paid = `${time_paid.getDate()}/${
      time_paid.getMonth() + 1
    }/${time_paid.getFullYear()}`;
  }
  return time_paid;
};

export const formatterPaidBy = (paidBy) => {
  if (paidBy) {
    if (/\b254\d{9}\b/.test(paidBy)) {
      paidBy = paidBy.replace(/\b254\d{9}\b/, "").trim();
    } else if (/\b07\d{8}\b/.test(paidBy)) {
      paidBy = paidBy.replace(/\b07\d{8}\b/, "").trim();
    }
  }

  return paidBy;
};
