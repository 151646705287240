import {
  Flex,
  FormControl,
  FormLabel,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  DrawerHeader,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import LoadingPage from "../LoadingPage";
import { ChevronDownIcon, ChevronUpIcon, UpDownIcon } from "@chakra-ui/icons";
import { getContributorsList } from "../../utils/api";
import { colorAlt, colorMain } from "../../constants/themes";
import DynamicButton from "./DynamicButton";

const ListItem = ({ item }) => (
  <Tr borderBottom="1px solid  #f8f9f9 ">
    <Td pl={5} fontWeight="semibold" color={colorMain}>
      {item.customer_name}
    </Td>
    <Td textAlign="left">
      Ksh. {item.total_payment_amount?.toLocaleString()}.00
    </Td>
  </Tr>
);

const renderSortArrow = (column, sortConfig) => {
  if (sortConfig.key === column) {
    return sortConfig.direction === "asc" ? (
      <Icon as={ChevronUpIcon} />
    ) : (
      <Icon as={ChevronDownIcon} />
    );
  }
  return null;
};

const ListsTable = ({ list, onSort, sortConfig }) => (
  <Table minW={["400px", "400px"]} size={["lg", "lg"]}>
    <Thead background="gray.100" w="100%">
      <Tr>
        <Th
          fontSize="2xl !important"
          color={colorMain}
          letterSpacing=".1rem"
          pl={3}
          display="flex"
          alignItems="center"
          onClick={() => onSort("customer_name")}
          textTransform="capitalize"
        >
          Name&nbsp;
          {renderSortArrow("customer_name", sortConfig) || (
            <Icon as={UpDownIcon} fontSize="md" />
          )}
        </Th>
        <Th
          fontSize="2xl  !important"
          color={colorMain}
          letterSpacing=".1rem"
          textAlign="left"
          textTransform="capitalize"
          onClick={() => onSort("total_payment_amount")}
        >
          <Flex alignItems="center" textAlign="left">
            Amount&nbsp;
            {renderSortArrow("total_payment_amount", sortConfig) || (
              <Icon as={UpDownIcon} fontSize="md" />
            )}
          </Flex>
        </Th>
      </Tr>
    </Thead>
    <Tbody>
      {list.map((item, index) => (
        <ListItem key={index} item={item} />
      ))}
    </Tbody>
  </Table>
);

const ListsDrawer = ({ trackCode, trigger, templateType }) => {
  const [isOpen, setIsOpen] = useState(false);
  let code = trackCode?.toUpperCase();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const handleSetOpen = () => {
    setLoading(true);
    setIsOpen(true);
    code && getContributorsList(code, setList, setLoading);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedList = [...list].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setList(sortedList);
  };

  const getActionWord = (templateType) =>
    templateType === "realestate"
      ? "Payments"
      : templateType === "events"
      ? "Purchases"
      : templateType === "fundraiser"
      ? "Contributions"
      : templateType === "business"
      ? "Buy"
      : templateType === "donations"
      ? "Donations"
      : templateType === "chamas"
      ? "Contributions"
      : "Contributions";

  return (
    <>
      {!trigger && (
        <Text
          onClick={handleSetOpen}
          colorMain={colorMain}
          textDecoration="underline"
          _hover={{
            cursor: "pointer",
          }}
          _focus={{
            cursor: "pointer",
          }}
        >
          {`View Other ${getActionWord(templateType)}`}
        </Text>
      )}

      <Drawer
        placement="bottom"
        closeOnOverlayClick={!loading}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isCentered
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexDir="column"
        motionPreset="slideInBottom"
        scrollBehavior="inside"
      >
        <>
          <DrawerOverlay />
          <DrawerContent borderTopRightRadius={12} borderTopLeftRadius={12}>
            <DrawerHeader
              fontSize="17px"
              fontWeight="medium"
              position="relative"
              color={colorMain}
              textAlign="center"
            >
              {`${getActionWord(templateType)}`} list
            </DrawerHeader>
            {!loading && (
              <DrawerCloseButton
                fontSize="md"
                fontWeight="bold"
                zIndex="1003"
              />
            )}
            <DrawerBody pb={0}>
              <FormControl>
                {loading ? (
                  <Flex
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    overflow="hidden"
                    maxH="50vh"
                  >
                    <LoadingPage />
                  </Flex>
                ) : (
                  <Flex
                    flex="1"
                    flexDir="column"
                    pt="5px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {list?.length > 0 ? (
                      <TableContainer>
                        <ListsTable
                          list={list}
                          onSort={handleSort}
                          sortConfig={sortConfig}
                        />
                      </TableContainer>
                    ) : (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        fontSize="3xl"
                        pb={5}
                        pt={2}
                        fontStyle="italic"
                        color={colorMain}
                      >
                        {list !== 0
                          ? "No records yet"
                          : "Contribution list is private"}
                      </Flex>
                    )}
                  </Flex>
                )}
              </FormControl>
            </DrawerBody>
          </DrawerContent>
        </>
      </Drawer>
    </>
  );
};

export default ListsDrawer;
